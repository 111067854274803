import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const CUSTOMERS_FETCH_REQUEST = 'customers/CUSTOMERS_FETCH_REQUEST';
export const CUSTOMERS_FETCH_SUCCESS = 'customers/CUSTOMERS_FETCH_SUCCESS';
export const CUSTOMERS_FETCH_ERROR = 'customers/CUSTOMERS_FETCH_ERROR';

export const CUSTOMER_FETCH_REQUEST = 'customers/CUSTOMER_FETCH_REQUEST';
export const CUSTOMER_FETCH_SUCCESS = 'customers/CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_FETCH_ERROR = 'customers/CUSTOMER_FETCH_ERROR';

export const CUSTOMER_UNLOAD_CURRENT = 'customers/CUSTOMER_UNLOAD_CURRENT';

export const CUSTOMER_CREATE_REQUEST = 'customers/CUSTOMER_CREATE_REQUEST';
export const CUSTOMER_CREATE_SUCCESS = 'customers/CUSTOMER_CREATE_SUCCESS';
export const CUSTOMER_CREATE_ERROR = 'customers/CUSTOMER_CREATE_ERROR';

export const CUSTOMER_MODIFY_REQUEST = 'customers/CUSTOMER_MODIFY_REQUEST';
export const CUSTOMER_MODIFY_SUCCESS = 'customers/CUSTOMER_MODIFY_SUCCESS';
export const CUSTOMER_MODIFY_ERROR = 'customers/CUSTOMER_MODIFY_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case CUSTOMERS_FETCH_REQUEST:
    case CUSTOMER_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case CUSTOMERS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.customer,
        result: action.payload.result,
      };
    case CUSTOMER_MODIFY_SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, [action.payload.id]: action.payload },
        current: action.payload,
      };
    case CUSTOMER_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case CUSTOMERS_FETCH_ERROR:
    case CUSTOMER_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case CUSTOMER_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
}

// Schemas
const customerSchema = new schema.Entity('customer');

// Operations

export function fetchCustomers(params) {
  return {
    [CALL_API]: {
      types: [
        CUSTOMERS_FETCH_REQUEST,
        CUSTOMERS_FETCH_SUCCESS,
        CUSTOMERS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/customer', params),
      schema: { data: [customerSchema] },
    },
  };
}

export function fetchCustomer(id) {
  return function (dispatch, getState) {
    const customersState = getState().customers;

    if (customersState.entities && id in customersState.entities) {
      // customer already exists in store
      dispatch({
        type: CUSTOMER_FETCH_SUCCESS,
        payload: customersState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            CUSTOMER_FETCH_REQUEST,
            CUSTOMER_FETCH_SUCCESS,
            CUSTOMER_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/customer/${id}`),
        },
      });
    }
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: CUSTOMER_UNLOAD_CURRENT });
  };
}

export function modifyCustomer(customer, onSuccess) {
  return {
    [CALL_API]: {
      types: [
        CUSTOMER_MODIFY_REQUEST,
        CUSTOMER_MODIFY_SUCCESS,
        CUSTOMER_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/customer/modify', customer),
      successMessage: 'Customer successfully modified',
      onSuccess,
    },
  };
}

export function createCustomer(customer) {
  return {
    [CALL_API]: {
      types: [
        CUSTOMER_CREATE_REQUEST,
        CUSTOMER_CREATE_SUCCESS,
        CUSTOMER_CREATE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/customer/modify', customer),
      successMessage: 'Customer successfully created',
    },
  };
}
