export const BEFORE_HOOK = 'Before';
export const AFTER_HOOK = 'After';

const hookHandler = (store) => (next) => (action) => {
  const beforeHandler = action[BEFORE_HOOK];
  const afterHandler = action[AFTER_HOOK];

  if (typeof beforeHandler === 'function') {
    beforeHandler(store, action);
  }

  const result = next(action);

  if (typeof afterHandler === 'function') {
    afterHandler(store, action);
  }

  return result;
};

export default hookHandler;
