import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import { withTranslation } from 'react-i18next';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import * as actions from 'redux/modules/notifyCenter';

const propTypesNC = {
  clearNotifications: PropTypes.func,
  t: PropTypes.func,
};

const defaultProps = {
  t: () => {},
};

class NotificationCenter extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { successMessage, errorMessage } = nextProps.notifications;

    if (successMessage) {
      toastr.success(this.props.t('success'), successMessage);
      this.props.clearNotifications();
    }

    if (errorMessage) {
      toastr.error(this.props.t('error'), errorMessage);
      this.props.clearNotifications();
    }
  }

  render() {
    return <ReduxToastr />;
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifyCenter,
  };
}

NotificationCenter.propTypes = propTypesNC;
NotificationCenter.defaultProps = defaultProps;

const extended = withTranslation()(NotificationCenter);
export default connect(mapStateToProps, actions)(extended);
