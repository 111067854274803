import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const END_USER_KEY_FETCH_REQUEST =
  'endUserKeys/END_USER_KEY_FETCH_REQUEST';
export const END_USER_KEY_FETCH_SUCCESS =
  'endUserKeys/END_USER_KEY_FETCHED_SUCCESS';
export const END_USER_KEY_FETCH_ERROR =
  'endUserKeys/END_USER_KEY_FETCHED_ERROR';

export const END_USER_KEYS_FETCH_REQUEST =
  'endUserKeys/END_USER_KEYS_FETCH_REQUEST';
export const END_USER_KEYS_FETCH_SUCCESS =
  'endUserKeys/END_USER_KEYS_FETCHED_SUCCESS';
export const END_USER_KEYS_FETCH_ERROR =
  'endUserKeys/END_USER_KEYS_FETCHED_ERROR';

export const END_USER_KEY_CREATE_REQUEST =
  'endUserKeys/END_USER_KEY_CREATE_REQUEST';
export const END_USER_KEY_CREATE_SUCCESS =
  'endUserKeys/END_USER_KEY_CREATE_SUCCESS';
export const END_USER_KEY_CREATE_ERROR =
  'endUserKeys/END_USER_KEY_CREATE_ERROR';

export const END_USER_KEY_MODIFY_REQUEST =
  'endUserKeys/END_USER_KEY_MODIFY_REQUEST';
export const END_USER_KEY_MODIFY_SUCCESS =
  'endUserKeys/END_USER_KEY_MODIFY_SUCCESS';
export const END_USER_KEY_MODIFY_ERROR =
  'endUserKeys/END_USER_KEY_MODIFY_ERROR';

export const END_USER_KEY_DELETE_REQUEST =
  'endUserKeys/END_USER_KEY_DELETE_REQUEST';
export const END_USER_KEY_DELETE_SUCCESS =
  'endUserKeys/END_USER_KEY_DELETE_SUCCESS';
export const END_USER_KEY_DELETE_ERROR =
  'endUserKeys/END_USER_KEY_DELETE_ERROR';

export const END_USER_KEY_UNLOAD_CURRENT =
  'endUserKeys/END_USER_KEY_UNLOAD_CURRENT';

export const CHANGE_USER_KEY_VALID_STATUS_REQUEST =
  'endUserKeys/CHANGE_USER_KEY_VALID_STATUS_REQUEST';
export const CHANGE_USER_KEY_VALID_STATUS_SUCCESS =
  'endUserKeys/CHANGE_USER_KEY_VALID_STATUS_SUCCESS';
export const CHANGE_USER_KEY_VALID_STATUS_ERROR =
  'endUserKeys/CHANGE_USER_KEY_VALID_STATUS_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
    deleting: false,
  },
  action
) {
  switch (action.type) {
    case END_USER_KEYS_FETCH_REQUEST:
    case END_USER_KEY_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case END_USER_KEYS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.endUserKeys,
        result: action.payload.result,
      };
    case END_USER_KEY_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };
    case END_USER_KEYS_FETCH_ERROR:
    case END_USER_KEY_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case END_USER_KEY_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    case END_USER_KEY_DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case END_USER_KEY_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case END_USER_KEY_DELETE_ERROR:
      return {
        ...state,
        deleting: false,
      };
    case CHANGE_USER_KEY_VALID_STATUS_SUCCESS: {
      const endUserkeyId = action.id;
      const list = { ...state.entities };

      list[endUserkeyId].valid = false; // @TODO set according to action

      return {
        ...state,
        entities: list,
      };
    }
    default:
      return state;
  }
}

// Schemas
const endUserKeySchema = new schema.Entity('endUserKeys');

// Operations
export function fetchEndUserKeys(params) {
  return {
    [CALL_API]: {
      types: [
        END_USER_KEYS_FETCH_REQUEST,
        END_USER_KEYS_FETCH_SUCCESS,
        END_USER_KEYS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/key', params),
      schema: { data: [endUserKeySchema] },
    },
  };
}

export function fetchEndUserKey(id) {
  return function (dispatch, getState) {
    const endUserKeysState = getState().endUserKeys;

    if (endUserKeysState.entities && id in endUserKeysState.entities) {
      // endUserKey already exists in store
      dispatch({
        type: END_USER_KEY_FETCH_SUCCESS,
        payload: endUserKeysState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            END_USER_KEY_FETCH_REQUEST,
            END_USER_KEY_FETCH_SUCCESS,
            END_USER_KEY_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/endUserKeys/${id}`),
        },
      });
    }
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: END_USER_KEY_UNLOAD_CURRENT });
  };
}

export function createEndUserKey(endUserKey) {
  return {
    [CALL_API]: {
      types: [
        END_USER_KEY_CREATE_REQUEST,
        END_USER_KEY_CREATE_SUCCESS,
        END_USER_KEY_CREATE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/lockers/paketbox/keys/create', endUserKey),
      successMessage: 'Authorization successfully created',
    },
  };
}

//! it seems like this is not actually used
export function changeValidStatus(authId, enable = true) {
  const action = enable ? 'validate' : 'invalidate';
  const textVerb = enable ? 'validated' : 'invalidated';

  return {
    id: authId,
    newValidStatus: enable,
    [CALL_API]: {
      types: [
        CHANGE_USER_KEY_VALID_STATUS_REQUEST,
        CHANGE_USER_KEY_VALID_STATUS_SUCCESS,
        CHANGE_USER_KEY_VALID_STATUS_ERROR,
      ],
      promise: (httpClient) => httpClient.get(`key/${authId}/${action}`),
      successMessage: `Authorization successfully ${textVerb}`,
    },
  };
}

export function deleteKey({ id, successMessage, errorMessage, onSuccess }) {
  return {
    [CALL_API]: {
      types: [
        END_USER_KEY_DELETE_REQUEST,
        END_USER_KEY_DELETE_SUCCESS,
        END_USER_KEY_DELETE_ERROR,
      ],
      promise: (httpClient) => httpClient.get(`key/${id}/smartDelete`),
      successMessage: successMessage || 'Key successfully deleted',
      errorMessage: errorMessage || 'There was a problem deleting the key',
      onSuccess: onSuccess || (() => null),
    },
  };
}
