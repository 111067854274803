import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const ALERTS_FETCH_REQUEST = 'alerts/ALERTS_FETCH_REQUEST';
export const ALERTS_FETCH_SUCCESS = 'alerts/ALERTS_FETCHED_SUCCESS';
export const ALERTS_FETCH_ERROR = 'alerts/ALERTS_FETCHED_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case ALERTS_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ALERTS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.alerts,
        result: action.payload.result,
      };

    case ALERTS_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Schemas
export const alertSchema = new schema.Entity('alerts');

// Operations
export function fetchAlerts(params) {
  return {
    [CALL_API]: {
      types: [ALERTS_FETCH_REQUEST, ALERTS_FETCH_SUCCESS, ALERTS_FETCH_ERROR],
      promise: (httpClient) => httpClient.post('/alert', params),
      schema: { data: [alertSchema] },
    },
  };
}
