import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const GROUPS_FETCH_REQUEST = 'groups/GROUPS_FETCH_REQUEST';
export const GROUPS_FETCH_SUCCESS = 'groups/GROUPS_FETCH_SUCCESS';
export const GROUPS_FETCH_ERROR = 'groups/GROUPS_FETCH_ERROR';

export const GROUP_FETCH_REQUEST = 'groups/GROUP_FETCH_REQUEST';
export const GROUP_FETCH_SUCCESS = 'groups/GROUP_FETCH_SUCCESS';
export const GROUP_FETCH_ERROR = 'groups/GROUP_FETCH_ERROR';

export const GROUP_UNLOAD_CURRENT = 'groups/GROUP_UNLOAD_CURRENT';

export const MODIFY_NAME_REQUEST = 'groups/MODIFY_NAME_REQUEST';
export const MODIFY_NAME_SUCCESS = 'groups/MODIFY_NAME_SUCCESS';
export const MODIFY_NAME_ERROR = 'groups/MODIFY_NAME_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case GROUPS_FETCH_REQUEST:
    case GROUP_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case GROUPS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.group,
        result: action.payload.result,
      };

    case GROUP_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case GROUPS_FETCH_ERROR:
    case GROUP_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case GROUP_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
}

// Schemas
const groupSchema = new schema.Entity('group');

// Operations

export function fetchGroups(params) {
  return {
    [CALL_API]: {
      types: [GROUPS_FETCH_REQUEST, GROUPS_FETCH_SUCCESS, GROUPS_FETCH_ERROR],
      promise: (httpClient) => httpClient.post('/group', params),
      schema: { data: [groupSchema] },
    },
  };
}

export function fetchGroup(id) {
  return function (dispatch, getState) {
    const groupsState = getState().groups;

    if (groupsState.entities && id in groupsState.entities) {
      // group already exists in store
      dispatch({
        type: GROUP_FETCH_SUCCESS,
        payload: groupsState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [GROUP_FETCH_REQUEST, GROUP_FETCH_SUCCESS, GROUP_FETCH_ERROR],
          promise: (httpClient) => httpClient.get(`/group/${id}`),
        },
      });
    }
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: GROUP_UNLOAD_CURRENT });
  };
}

export function modifyGroupName({ id, name }) {
  return {
    [CALL_API]: {
      types: [MODIFY_NAME_REQUEST, MODIFY_NAME_SUCCESS, MODIFY_NAME_ERROR],
      promise: (httpClient) =>
        httpClient.post('/group/updateName', { id, name }),
      successMessage: 'Group name successfully updated',
    },
  };
}
