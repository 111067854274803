import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const LANGUAGES_FETCH_REQUEST = 'languages/LANGUAGES_FETCH_REQUEST';
export const LANGUAGES_FETCH_SUCCESS = 'languages/LANGUAGES_FETCHED_SUCCESS';
export const LANGUAGES_FETCH_ERROR = 'languages/LANGUAGES_FETCHED_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case LANGUAGES_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case LANGUAGES_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.languages,
        result: action.payload.result,
      };

    case LANGUAGES_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Schemas
export const languageSchema = new schema.Entity('languages');

// Operations
export function fetchLanguages(params) {
  return {
    [CALL_API]: {
      types: [
        LANGUAGES_FETCH_REQUEST,
        LANGUAGES_FETCH_SUCCESS,
        LANGUAGES_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/language', params),
      schema: { data: [languageSchema] },
    },
  };
}
