import { css } from 'styled-components/macro';
import 'typeface-rajdhani';

export default css`
  body {
    font-family: ${[
      'Rajdhani',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(',')};
    background-color: #1b2433;
  }

  b {
    font-weight: bold;
  }

  .clear {
    clear: both;
  }

  .centerAlign {
    text-align: center;
  }

  .notes-table .rt-td {
    white-space: normal !important;
  }

  .mui-table td,
  .mui-table th {
    vertical-align: middle;
  }
  /* Blinking animation */
  @keyframes blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  span.blink {
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
    animation-duration: 1s;
  }

  .mplDetails > div > div {
    max-width: none !important;
    width: 85% !important;
  }

  .mplDetails {
    padding-top: 10px !important;
  }

  .outerDiv > div > div {
    width: 240px !important;
  }

  @media only screen and (max-width: 1400px) {
    .outerDiv > div > div {
      width: 220px !important;
    }
    .autocomplete {
      width: 190px !important;
    }
    .configurationDisplay {
      width: 220px !important;
      word-wrap: break-word !important;
    }
    .configurationDisplay > p {
      word-break: break-all;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    .outerDiv > div > div {
      width: 170px !important;
    }
    .autocomplete {
      width: 170px !important;
    }
    .configurationDisplay {
      width: 170px !important;
      word-wrap: break-word !important;
    }
    .configurationDisplay > p {
      word-break: break-all;
    }
    .masterConfigField {
      width: 240px !important;
    }
  }

  @media only screen and (max-width: 451px) {
    .pageBase > div > div {
      float: none !important;
      margin-bottom: 15px !important;
      padding-left: 10px !important;
    }

    .pageBase > div > h3 {
      margin-bottom: 0px !important;
    }

    .lfUUXV {
      width: 95px !important;
    }

    .appBar > div {
      margin-right: 0px !important;
    }

    .sc-gzVnrw > a > button {
      min-width: 48px !important;
    }
  }

  @media only screen and (max-width: 414px) {
    .outerDiv {
      padding-left: 30px !important;
      max-width: 100%;
    }
    .right {
      margin: initial !important;
      padding-right: 60px !important;
    }
    .autocomplete {
      float: right !important;
    }
    .left {
      float: none !important;
    }
    .lePPqA {
      max-width: 30%;
      right: 15px;
    }
    .alertType_email > label {
      font-size: 14px !important;
    }
  }

  /* @media only screen and (max-width: 767px) {
    .leftDrawer > div {
      background-color: #1b2433;
    }
  } */

  .tabItems > div > button {
    color: white !important;
    text-shadow: 1px 1px black;
    margin-right: 1px !important;
    /* TODO: Edit this colour */
    background-color: rgb(211, 166, 0) !important;
  }

  .UserAccessibilityContainer .col-xs-6 {
    padding-left: 0px;
  }

  .main-content {
    border: solid 1px #ff941a;
  }

  /* Date range custom styles (nesting for specificity) */
  .DateRangePicker {
    .DateRangePickerInput {
      background-color: transparent;

      .DateInput {
        background-color: transparent;
      }

      .DateInput__display-text {
        color: #ff941a;
      }

      .DateInput__display-text--focused {
        border-color: #66738b;
        background: #66738b;
      }
    }

    .DateRangePickerInput__arrow svg {
      fill: #ff941a;
    }
  }

  .DateRangePicker__picker--portal {
    .DayPicker,
    .DayPicker--horizontal {
      background-color: #182740;
    }

    .CalendarMonth,
    .CalendarMonthGrid {
      background-color: #182740;
    }

    .CalendarMonth__caption {
      color: #ff951a;
    }

    .DayPickerNavigation--horizontal .DayPickerNavigation__prev,
    .DayPickerNavigation--horizontal .DayPickerNavigation__next {
      background-color: #182740;
      border: 1px solid #ff951a;
      color: #ff951a;
    }

    .DayPicker__week-header {
      color: #ff951a;
    }

    .CalendarDay {
      border: 1px solid #ff951a;
      color: #ff951a;
    }
    .CalendarDay--today,
    .CalendarDay--selected-start,
    .CalendarDay--selected-end,
    .CalendarDay--selected,
    .CalendarDay--selected-span,
    .CalendarDay--hovered,
    .CalendarDay--hovered-span,
    .CalendarDay--after-hovered-start,
    .CalendarDay--selected-span.CalendarDay--hovered {
      background-color: #ff951a;
      color: #182740;
    }
  }

  .login-window {
    background-image: linear-gradient(to bottom, #17273f, #222e43);

    /* override webkit autofill styling */
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px rgb(34, 46, 67) inset;
      -webkit-text-fill-color: #ff941a;
    }

    input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px rgb(34, 46, 67) inset;
      -webkit-text-fill-color: #ff941a;
    }

    input {
      color: #ff941a;
    }
    label {
      color: #ff941a;
    }
  }
`;
