import { createMuiTheme } from '@material-ui/core';

export const red = '#ED1B2D';
export const yellow = '#FF8F00';
export const black = '#221E1F';

// refresh button and coloured buttons
export const primary1Color = {
  main: '#00ADEE',
  contrastText: '#fff',
};
// subtitle backgrounds - should be darker
export const primary2Color = {
  main: '#A89D78',
  contrastText: '#A89D78',
};
// // table links and tab highlight color
// export const primary3Color = '#D1AA66';
// text for left menu items
export const drawerTextColor = 'rgba(0,0,0,0.7)';
// text for top menu icons
export const headerIconColor = 'rgba(0,0,0,0.7)';

const theme = createMuiTheme({
  palette: {
    primary: primary1Color,
    secondary: primary2Color,
    background: {
      paper: '#fff',
      default: '#E7E6E1',
    },
    type: 'light',
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
  },

  overrides: {
    MuiTextField: {
      root: {
        margin: '0.9rem 0',
      },
    },
    MuiSelect: {
      select: {
        fontSize: 14,
      },
    },
  },
});

export default {
  ...theme,
  drawerTextColor,
  headerIconColor,
};

// import getMuiTheme from 'material-ui/styles/getMuiTheme';
// import cyan from '@material-ui/core/colors/cyan';
// import pink from '@material-ui/core/colors/pink';
// import grey from '@material-ui/core/colors/grey';
// import common from '@material-ui/core/colors/common';
// const cyan500 = cyan['500'];
// const cyan700 = cyan['700'];
// const pinkA200 = pink.A200;
// const grey900 = grey['900'];
// const grey100 = grey['100'];
// const grey300 = grey['300'];
// const grey400 = grey['400'];
// const grey500 = grey['500'];
// const white = common.white;
// const darkBlack = common.darkBlack;
// const fullBlack = common.fullBlack;

// export const useDrawer = true;

// export const red = '#ED1B2D';
// export const yellow = '#FF8F00';
// export const black = '#221E1F';

// // refresh button and coloured buttons
// export const primary1Color = '#00ADEE';
// // subtitle backgrounds - should be darker
// export const primary2Color = '#A89D78';
// // table links and tab highlight color
// export const primary3Color = '#D1AA66';
// // text for left menu items
// export const drawerTextColor = 'rgba(0,0,0,0.7)';
// // text for top menu icons
// export const headerIconColor = 'rgba(0,0,0,0.7)';

// export default {
//   // custom theme
//   useDrawer,
//   red,
//   yellow,
//   black,
//   primary1Color,
//   primary2Color,
//   primary3Color,
//   drawerTextColor,
//   headerIconColor,

//   // mui theme
//   ...getMuiTheme({
//     palette: {
//       primary1Color,
//       primary2Color: cyan700,
//       primary3Color: grey400,
//       accent1Color: pinkA200,
//       accent2Color: grey100,
//       accent3Color: grey500,
//       textColor: darkBlack,
//       alternateTextColor: white,
//       canvasColor: white,
//       borderColor: grey300,
//       pickerHeaderColor: cyan500,
//       shadowColor: fullBlack,
//     },
//     appBar: {
//       height: 57,
//       textColor: darkBlack,
//       color: 'transparent',
//     },
//     drawer: {
//       width: 230,
//       color: grey900,
//     },
//     raisedButton: {
//       primaryColor: primary1Color,
//     },
//     tabs: {
//       backgroundColor: white,
//       textColor: black,
//       opacity: '0',
//       selectedTextColor: black,
//     },
//   }),
// };
