import logoLogin from './logo_login.png';
import logo from './logo.png';
import wordmark from './wordmark.png';

export default {
  logoLogin,
  logo,
  wordmark,
  favicon: logo,
};
