import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';
import { AFTER_HOOK } from '../middleware/hookHandler';
import omit from 'lodash.omit';
import * as masterConfigActions from './masterConfiguration';

// ACTIONS
export const ACCESS_REQUEST_GRANT_REQUEST =
  'accessRequests/ACCESS_REQUEST_GRANT_REQUEST';
export const ACCESS_REQUEST_GRANT_SUCCESS =
  'accessRequests/ACCESS_REQUEST_FETCHED_SUCCESS';
export const ACCESS_REQUEST_GRANT_ERROR =
  'accessRequests/ACCESS_REQUEST_FETCHED_ERROR';

export const ACCESS_REQUESTS_FETCH_REQUEST =
  'accessRequests/ACCESS_REQUESTS_FETCH_REQUEST';
export const ACCESS_REQUESTS_FETCH_SUCCESS =
  'accessRequests/ACCESS_REQUESTS_FETCHED_SUCCESS';
export const ACCESS_REQUESTS_FETCH_ERROR =
  'accessRequests/ACCESS_REQUESTS_FETCHED_ERROR';

export const ACCESS_REQUEST_CREATE_REQUEST =
  'accessRequests/ACCESS_REQUEST_CREATE_REQUEST';
export const ACCESS_REQUEST_CREATE_SUCCESS =
  'accessRequests/ACCESS_REQUEST_CREATE_SUCCESS';
export const ACCESS_REQUEST_CREATE_ERROR =
  'accessRequests/ACCESS_REQUEST_CREATE_ERROR';

export const ACCESS_REQUEST_MODIFY_REQUEST =
  'accessRequests/ACCESS_REQUEST_MODIFY_REQUEST';
export const ACCESS_REQUEST_MODIFY_SUCCESS =
  'accessRequests/ACCESS_REQUEST_MODIFY_SUCCESS';
export const ACCESS_REQUEST_MODIFY_ERROR =
  'accessRequests/ACCESS_REQUEST_MODIFY_ERROR';

export const ACCESS_REQUEST_UNLOAD_CURRENT =
  'accessRequests/ACCESS_REQUEST_UNLOAD_CURRENT';

export const CHANGE_USER_VALID_STATUS_REQUEST =
  'accessRequests/CHANGE_USER_VALID_STATUS_REQUEST';
export const CHANGE_USER_VALID_STATUS_SUCCESS =
  'accessRequests/CHANGE_USER_VALID_STATUS_SUCCESS';
export const CHANGE_USER_VALID_STATUS_ERROR =
  'accessRequests/CHANGE_USER_VALID_STATUS_ERROR';

export const ACCESS_CONFIGURATION_DELETE_REQUEST =
  'accessRequests/ACCESS_CONFIGURATION_DELETE_REQUEST';
export const ACCESS_CONFIGURATION_DELETE_SUCCESS =
  'accessRequests/ACCESS_CONFIGURATION_DELETE_SUCCESS';
export const ACCESS_CONFIGURATION_DELETE_ERROR =
  'accessRequests/ACCESS_CONFIGURATION_DELETE_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case ACCESS_REQUESTS_FETCH_REQUEST:
    case ACCESS_REQUEST_GRANT_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ACCESS_REQUESTS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.accessRequests,
        result: action.payload.result,
      };
    case ACCESS_REQUEST_GRANT_SUCCESS:
      const updatedEntities = omit(state.entities, action.id);
      return {
        ...state,
        entities: updatedEntities,
      };

    case ACCESS_CONFIGURATION_DELETE_SUCCESS:
      const notDeletedEntities = omit(state.entities, action.id);
      return {
        ...state,
        entities: notDeletedEntities,
      };

    case ACCESS_REQUESTS_FETCH_ERROR:
    case ACCESS_REQUEST_GRANT_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case ACCESS_REQUEST_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };

    case CHANGE_USER_VALID_STATUS_SUCCESS: {
      const userId = action.id;
      const newValidStatus = action.newValidStatus;
      const list = Object.assign({}, state.entities);

      if (userId in list) {
        list[userId].valid = newValidStatus;
      }

      const newCurrent = Object.assign({}, state.current);
      if (state.current && state.current.id === userId) {
        newCurrent.valid = newValidStatus;
      }

      return {
        ...state,
        current: newCurrent,
        entities: list,
      };
    }
    default:
      return state;
  }
}

// Schemas
const accessRequestSchema = new schema.Entity('accessRequests');

// Operations
export function fetchAccessRequests(params) {
  return {
    [CALL_API]: {
      types: [
        ACCESS_REQUESTS_FETCH_REQUEST,
        ACCESS_REQUESTS_FETCH_SUCCESS,
        ACCESS_REQUESTS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/masterAccessRequest', params),
      schema: { data: [accessRequestSchema] },
    },
  };
}

export function grantAccessRequest(id, btnIndex) {
  return function (dispatch) {
    dispatch({
      id,
      [CALL_API]: {
        types: [
          ACCESS_REQUEST_GRANT_REQUEST,
          ACCESS_REQUEST_GRANT_SUCCESS,
          ACCESS_REQUEST_GRANT_ERROR,
        ],
        promise: (httpClient) =>
          httpClient.get(`/masterAccessRequest/grant/${id}/${btnIndex}`),
        successMessage: 'User successfully granted',
      },
      [AFTER_HOOK]: (storeApi, action) => {
        if (action.type === ACCESS_REQUEST_GRANT_SUCCESS) {
          dispatch(masterConfigActions.fetchMasterConfigurations());
        }
      },
    });
  };
}

export function deleteAccessRequest(id) {
  return {
    id,
    [CALL_API]: {
      types: [
        ACCESS_CONFIGURATION_DELETE_REQUEST,
        ACCESS_CONFIGURATION_DELETE_SUCCESS,
        ACCESS_CONFIGURATION_DELETE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.delete(`/masterAccessRequest/${id}/delete`),
      successMessage: 'Access Request successfully deleted',
    },
  };
}
