import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const ALERT_TYPE_FETCH_REQUEST = 'alertTypes/ALERT_TYPE_FETCH_REQUEST';
export const ALERT_TYPE_FETCH_SUCCESS = 'alertTypes/ALERT_TYPE_FETCHED_SUCCESS';
export const ALERT_TYPE_FETCH_ERROR = 'alertTypes/ALERT_TYPE_FETCHED_ERROR';

export const ALERT_TYPES_FETCH_REQUEST = 'alertTypes/ALERT_TYPES_FETCH_REQUEST';
export const ALERT_TYPES_FETCH_SUCCESS =
  'alertTypes/ALERT_TYPES_FETCHED_SUCCESS';
export const ALERT_TYPES_FETCH_ERROR = 'alertTypes/ALERT_TYPES_FETCHED_ERROR';

export const ALERT_TYPE_CREATE_REQUEST = 'alertTypes/ALERT_TYPE_CREATE_REQUEST';
export const ALERT_TYPE_CREATE_SUCCESS = 'alertTypes/ALERT_TYPE_CREATE_SUCCESS';
export const ALERT_TYPE_CREATE_ERROR = 'alertTypes/ALERT_TYPE_CREATE_ERROR';

export const ALERT_TYPE_MODIFY_REQUEST = 'alertTypes/ALERT_TYPE_MODIFY_REQUEST';
export const ALERT_TYPE_MODIFY_SUCCESS = 'alertTypes/ALERT_TYPE_MODIFY_SUCCESS';
export const ALERT_TYPE_MODIFY_ERROR = 'alertTypes/ALERT_TYPE_MODIFY_ERROR';

export const ALERT_TYPE_UNLOAD_CURRENT = 'alertTypes/ALERT_TYPE_UNLOAD_CURRENT';

// REDUCER
export default function reducer(
  state = {
    entities: {},
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case ALERT_TYPES_FETCH_REQUEST:
    case ALERT_TYPE_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ALERT_TYPES_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.alertTypes,
        result: action.payload.result,
      };
    case ALERT_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };
    case ALERT_TYPES_FETCH_ERROR:
    case ALERT_TYPE_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case ALERT_TYPE_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
}

// Schemas
const alertTypeSchema = new schema.Entity('alertTypes');

// Operations
export function fetchAlertTypes(params) {
  return {
    [CALL_API]: {
      types: [
        ALERT_TYPES_FETCH_REQUEST,
        ALERT_TYPES_FETCH_SUCCESS,
        ALERT_TYPES_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/alertType', params),
      schema: { data: [alertTypeSchema] },
    },
  };
}

export function fetchAlertType(id) {
  return function (dispatch, getState) {
    const alertTypesState = getState().alertTypes;

    if (alertTypesState.entities && id in alertTypesState.entities) {
      // alertType already exists in store
      dispatch({
        type: ALERT_TYPE_FETCH_SUCCESS,
        payload: alertTypesState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            ALERT_TYPE_FETCH_REQUEST,
            ALERT_TYPE_FETCH_SUCCESS,
            ALERT_TYPE_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/alertType/${id}`),
        },
      });
    }
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: ALERT_TYPE_UNLOAD_CURRENT });
  };
}

export function modifyAlertType({ alertType, onSuccess }) {
  return {
    [CALL_API]: {
      types: [
        ALERT_TYPE_MODIFY_REQUEST,
        ALERT_TYPE_MODIFY_SUCCESS,
        ALERT_TYPE_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/alertType/update', alertType),
      successMessage: 'Alert type successfully updated',
      onSuccess,
    },
  };
}
