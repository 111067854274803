import { normalize } from 'normalizr';

import httpClient from '../../utils/httpClient';
import { UNAUTHORIZED } from '../modules/auth';
import { ERRORS } from '../../config';
import i18n from '../../translations/i18n';

export const CALL_API = 'Call API';

function validateCallAPI(types) {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }

  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  return true;
}
/*
  CALL_API: {
    types: (required) [REQUEST_TYPE, SUCCESS_TYPE, ERROR_TYPE]
    promise: (required) function returning a promise
    schema: (optional) response data will be mapped to scheme
    notifyOnError: (optional) if false, error wont be forwarded to notifyCenter
    successMessage: (optional)  if set, message will be forwarded to notifyCenter
    unauthorizedMessage: (optional) if set, message will be forwarded to notifyCenter
  }
  ... all other fields will be forwarded to reducer
 */

const apiMiddleware = () => (next) => (action) => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const {
    types,
    promise,
    schema,
    notifyOnError,
    successMessage,
    unauthorizedMessage,
  } = callAPI;

  validateCallAPI(types, promise, schema);

  // const actionWith = (data) => {
  //   const finalAction = { ...action, ...data };
  //   delete finalAction[CALL_API];
  //   return finalAction;
  // };

  const [requestType, successType, failureType] = types;
  next({ type: requestType, ...action });

  const actionPromise = promise(httpClient);

  actionPromise
    .then((response) => {
      const data = schema ? normalize(response.data, schema) : response.data;

      const nextAction = {
        type: successType,
        payload: data,
        ...action,
      };
      if (callAPI.onSuccess) callAPI.onSuccess(response.data);
      if (successMessage) {
        nextAction.successMessage = successMessage;
      }
      return next(nextAction);
    })
    .catch((err) => {
      const nextAction = {
        type: failureType,
        payload: err,
        ...action,
      };

      if (err.response) {
        // dont show error alert only if notifyOnError is set to false
        if (err.response && !err.response.status) {
          nextAction.errorMessage =
            'Cannot connect to server. Please try again later or contact the support';
        } else if (notifyOnError !== false && err.response.status !== 401) {
          let message =
            err.response &&
            err.response.data &&
            err.response.data.error_message;

          if (
            err.response &&
            err.response.data &&
            err.response.data.error_code &&
            err.response.data.error_code in ERRORS
          ) {
            message = i18n.t(`errors.${ERRORS[err.response.data.error_code]}`);
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error_message
          ) {
            message = err.response.data.error_message;
          } else {
            message = 'Something went wrong';
          }

          nextAction.errorMessage = message;
        } else if (err.response.status === 401) {
          return next({
            type: UNAUTHORIZED,
            errorMessage: unauthorizedMessage || false,
            payload: err,
            ...action,
          });
        }
      } else if (err.code === 'ECONNABORTED') {
        // timeout
        nextAction.errorMessage =
          'Cannot connect to server. Please try again later or contact the support';
      } else {
        throw err;
      }

      return next(nextAction);
    });
};

export default apiMiddleware;
