import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/icons/Menu';
import AccountIcon from '@material-ui/icons/AccountCircle';
import styled from 'styled-components/macro';
import { withTranslation } from 'react-i18next';
import { AppBar, IconButton, Button } from '@material-ui/core';

import UserMenu from './UserMenu';
import brands from '../../brands';

const project = process.env.REACT_APP_PROJECT;
const { theme, wordmark } = brands[project];
const { headerIconColor } = theme;

const AppBarStyled = styled(AppBar)`
  position: relative;
  top: 0;
  overflow: hidden;
  width: auto;
  height: 57px;
  padding: 0 28px;
  box-shadow: rgba(0, 0, 0, 0.12) 4px 1px 6px, rgba(0, 0, 0, 0.12) 4px 1px 4px;
  background: transparent;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const DrawerContainer = styled.div`
  width: 30px;
`;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const TextLogo = styled.img`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 20px;
  height: ${project === 'smartboxbasel' ? '30px' : '20px'};
`;

class Header extends React.Component {
  render() {
    const { handleChangeRequestNavDrawer, user } = this.props;

    const userBlock = (
      <Link to={`/accounts/modify/${user.id}`}>
        <Button
          style={{
            color: headerIconColor,
            verticalAlign: 'super',
            top: -4,
            position: 'relative',
          }}
        >
          <AccountIcon />
        </Button>
      </Link>
    );

    return (
      <AppBarStyled style={this.props.style} className="appBar">
        <Container>
          <DrawerContainer>
            <IconButton
              onClick={handleChangeRequestNavDrawer}
              style={{ color: headerIconColor }}
              data-cy="toggle-nav-button"
            >
              <Menu />
            </IconButton>
          </DrawerContainer>
          {wordmark && <TextLogo className="wordmark" src={wordmark} />}
          <OptionsContainer>
            {userBlock}
            <UserMenu iconColor={headerIconColor} t={this.props.t} />
          </OptionsContainer>
        </Container>
      </AppBarStyled>
    );
  }
}

Header.propTypes = {
  menus: PropTypes.array,
  handleChangeRequestNavDrawer: PropTypes.func,
  user: PropTypes.any,
  style: PropTypes.any,
  t: PropTypes.func,
};

Header.defaultProps = {
  menus: [],
  t: () => {},
};

export default withTranslation()(Header);
