export const CLEAR_NOTIFICATIONS = 'notify/CLEAR_NOTIFICATIONS';
export const GENERIC_ERROR = 'notify/GENERIC_ERROR';
export const GENERIC_SUCCESS = 'notify/GENERIC_SUCCESS';

export default function reducer(
  state = { successMessage: '', errorMessage: '' },
  action
) {
  if (action.type === CLEAR_NOTIFICATIONS) {
    return { ...state, successMessage: '', errorMessage: '' };
  } else if (action.errorMessage) {
    return { ...state, successMessage: '', errorMessage: action.errorMessage };
  } else if (action.successMessage) {
    return {
      ...state,
      successMessage: action.successMessage,
      errorMessage: '',
    };
  }
  return state;
}

export function clearNotifications() {
  return function (dispatch) {
    dispatch({ type: CLEAR_NOTIFICATIONS });
  };
}

export function showError(errorMessage) {
  return function (dispatch) {
    dispatch({ type: GENERIC_ERROR, errorMessage });
  };
}

export function showSuccess(successMessage) {
  return function (dispatch) {
    dispatch({ type: GENERIC_SUCCESS, successMessage });
  };
}
