import { createMuiTheme } from '@material-ui/core';

export const red = '#ED1B2D';
export const yellow = '#FF8F00';
export const black = '#221E1F';

const orange = {
  main: '#FF951A',
  contrastText: '#1B2433',
};
const cold = {
  light: '#66738B',
  main: '#3a4a62',
  dark: '#1B2332',
  contrastText: '#fff',
};

// text for left menu items
export const drawerTextColor = orange.main;
// text for top menu icons
export const headerIconColor = orange.main;

const theme = createMuiTheme({
  palette: {
    primary: cold,
    secondary: cold,
    text: {
      primary: cold.dark,
      secondary: cold.dark,
    },
    background: {
      paper: '#fff',
      default: cold.dark,
    },
    action: {
      active: cold.main,
    },
    divider: '#E2E4E8',
  },
  typography: {
    fontFamily: [
      'Rajdhani',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 14,
  },
  shape: {
    borderRadius: '11px',
  },
  overrides: {
    MuiTextField: {
      root: {
        margin: '0.9rem 0',
      },
    },
    MuiSelect: {
      select: {
        fontSize: 14,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '4px 8px 20px 0 rgba(0, 0, 0, 0.26)',
      },
    },
    MuiInput: {
      // input: {
      //   // padding: '0.5rem 1rem',
      // },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${cold.main}`,
        },
      },
    },
  },
});

export default {
  ...theme,
  drawerTextColor,
  headerIconColor,
};
