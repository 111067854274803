import { schema } from 'normalizr';
import omit from 'lodash.omit';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const NETWORK_CONFIGURATIONS_FETCH_REQUEST =
  'networkConfigurations/NETWORK_CONFIGURATIONS_FETCH_REQUEST';
export const NETWORK_CONFIGURATIONS_FETCH_SUCCESS =
  'networkConfigurations/NETWORK_CONFIGURATIONS_FETCH_SUCCESS';
export const NETWORK_CONFIGURATIONS_FETCH_ERROR =
  'networkConfigurations/NETWORK_CONFIGURATIONS_FETCH_ERROR';

export const NETWORK_CONFIGURATION_MODIFY_REQUEST =
  'networkConfigurations/NETWORK_CONFIGURATION_MODIFY_REQUEST';
export const NETWORK_CONFIGURATION_MODIFY_SUCCESS =
  'networkConfigurations/NETWORK_CONFIGURATION_MODIFY_SUCCESS';
export const NETWORK_CONFIGURATION_MODIFY_ERROR =
  'networkConfigurations/NETWORK_CONFIGURATION_MODIFY_ERROR';

export const NETWORK_CONFIGURATION_CREATE_REQUEST =
  'networkConfigurations/NETWORK_CONFIGURATION_CREATE_REQUEST';
export const NETWORK_CONFIGURATION_CREATE_SUCCESS =
  'networkConfigurations/NETWORK_CONFIGURATION_CREATE_SUCCESS';
export const NETWORK_CONFIGURATION_CREATE_ERROR =
  'networkConfigurations/NETWORK_CONFIGURATION_CREATE_ERROR';

export const NETWORK_CONFIGURATION_FETCH_REQUEST =
  'networkConfigurations/NETWORK_CONFIGURATION_FETCH_REQUEST';
export const NETWORK_CONFIGURATION_FETCH_SUCCESS =
  'networkConfigurations/NETWORK_CONFIGURATION_FETCH_SUCCESS';
export const NETWORK_CONFIGURATION_FETCH_ERROR =
  'networkConfigurations/NETWORK_CONFIGURATION_FETCH_ERROR';

export const NETWORK_CONFIGURATION_DELETE_REQUEST =
  'networkConfiguration/NETWORK_CONFIGURATION_DELETE_REQUEST';
export const NETWORK_CONFIGURATION_DELETE_SUCCESS =
  'networkConfiguration/NETWORK_CONFIGURATION_DELETE_SUCCESS';
export const NETWORK_CONFIGURATION_DELETE_ERROR =
  'networkConfiguration/NETWORK_CONFIGURATION_DELETE_ERROR';

export const NETWORK_CONFIGURATION_UNLOAD_CURRENT =
  'networkConfigurations/NETWORK_CONFIGURATION_UNLOAD_CURRENT';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
    image: null,
  },
  action
) {
  switch (action.type) {
    case NETWORK_CONFIGURATIONS_FETCH_REQUEST:
    case NETWORK_CONFIGURATION_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case NETWORK_CONFIGURATION_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case NETWORK_CONFIGURATION_DELETE_SUCCESS:
      const updatedEntities = omit(state.entities, action.id);
      return {
        ...state,
        entities: updatedEntities,
      };

    case NETWORK_CONFIGURATIONS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.networkConfigurations,
        result: action.payload.result,
      };

    case NETWORK_CONFIGURATIONS_FETCH_ERROR:
    case NETWORK_CONFIGURATION_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case NETWORK_CONFIGURATION_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
}

// Schemas
const networkConfigurationSchema = new schema.Entity('networkConfigurations');

// Operations
export function fetchNetworkConfigurations(params) {
  return {
    [CALL_API]: {
      types: [
        NETWORK_CONFIGURATIONS_FETCH_REQUEST,
        NETWORK_CONFIGURATIONS_FETCH_SUCCESS,
        NETWORK_CONFIGURATIONS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/networkConfiguration', params),
      schema: { data: [networkConfigurationSchema] },
    },
  };
}

export function fetchNetworkConfiguration(id) {
  return function (dispatch, getState) {
    const networkConfigurationsState = getState().networkConfigurations;
    if (
      networkConfigurationsState.entities &&
      id in networkConfigurationsState.entities
    ) {
      // networkConfiguration already exists in store
      dispatch({
        type: NETWORK_CONFIGURATION_FETCH_SUCCESS,
        payload: networkConfigurationsState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            NETWORK_CONFIGURATION_FETCH_REQUEST,
            NETWORK_CONFIGURATION_FETCH_SUCCESS,
            NETWORK_CONFIGURATION_FETCH_ERROR,
          ],
          promise: (httpClient) =>
            httpClient.get(`/networkConfiguration/${id}`),
        },
      });
    }
  };
}

export function modifyNetworkConfiguration(networkConfiguration) {
  return {
    [CALL_API]: {
      types: [
        NETWORK_CONFIGURATION_MODIFY_REQUEST,
        NETWORK_CONFIGURATION_MODIFY_SUCCESS,
        NETWORK_CONFIGURATION_MODIFY_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post(`/networkConfiguration/modify`, networkConfiguration),
      successMessage: 'Network Configuration successfully modified',
    },
  };
}

export function createNetworkConfiguration({
  networkConfiguration,
  onSuccess,
}) {
  return {
    [CALL_API]: {
      types: [
        NETWORK_CONFIGURATION_CREATE_REQUEST,
        NETWORK_CONFIGURATION_CREATE_SUCCESS,
        NETWORK_CONFIGURATION_CREATE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/networkConfiguration/modify', networkConfiguration),
      successMessage: 'Network configuration successfully created',
      onSuccess,
    },
  };
}

export function deleteNetworkConfiguration(id) {
  return {
    id,
    [CALL_API]: {
      types: [
        NETWORK_CONFIGURATION_DELETE_REQUEST,
        NETWORK_CONFIGURATION_DELETE_SUCCESS,
        NETWORK_CONFIGURATION_DELETE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.delete(`/networkConfiguration/${id}/delete`),
      successMessage: 'Network configuration successfully deleted',
    },
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: NETWORK_CONFIGURATION_UNLOAD_CURRENT });
  };
}
