import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';
import 'reset-css/reset.css';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import store from './redux/store';
import NotificationCenter from './NotificationCenter';

import GlobalStyle from './assets/styles/GlobalStyle';
import './assets/styles/map_marker.css';
import './assets/styles/material-icons.override.css';
import './assets/styles/react-table.override.css';

// react query client
const queryClient = new QueryClient();

render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <StylesProvider injectFirst>
        <GlobalStyle />
        <App />
        <NotificationCenter />
      </StylesProvider>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);

// expose Redux store when running Cypress
if (window.Cypress) {
  window.store = store;
}
