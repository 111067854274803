import logoLogin from './logo_login.png';
import logo from './logo.png';
import wordmark from './wordmark.png';

const images = {
  logoLogin,
  logo,
  wordmark,
  favicon: logo,
};

export default images;
