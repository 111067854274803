import { schema } from 'normalizr';
import omit from 'lodash.omit';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const GROUP_PINS_FETCH_REQUEST = 'groupPins/GROUP_PINS_FETCH_REQUEST';
export const GROUP_PINS_FETCH_SUCCESS = 'groupPins/GROUP_PINS_FETCH_SUCCESS';
export const GROUP_PINS_FETCH_ERROR = 'groupPins/GROUP_PINS_FETCH_ERROR';

export const GROUP_PIN_MODIFY_REQUEST = 'groupPins/GROUP_PIN_MODIFY_REQUEST';
export const GROUP_PIN_MODIFY_SUCCESS = 'groupPins/GROUP_PIN_MODIFY_SUCCESS';
export const GROUP_PIN_MODIFY_ERROR = 'groupPins/GROUP_PIN_MODIFY_ERROR';

export const GROUP_PIN_DELETE_REQUEST = 'groupPins/GROUP_PIN_DELETE_REQUEST';
export const GROUP_PIN_DELETE_SUCCESS = 'groupPins/GROUP_PIN_DELETE_SUCCESS';
export const GROUP_PIN_DELETE_ERROR = 'groupPins/GROUP_PIN_DELETE_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    fetching: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case GROUP_PINS_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case GROUP_PINS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        entities: action.payload.entities.groupPins,
        result: action.payload.result,
      };

    case GROUP_PINS_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case GROUP_PIN_DELETE_SUCCESS:
      const updatedEntities = omit(state.entities, action.id);
      return {
        ...state,
        entities: updatedEntities,
      };
    default:
      return state;
  }
}

// Schemas
const groupPinsSchema = new schema.Entity('groupPins');

// Operations
export function fetchGroupPins() {
  return {
    [CALL_API]: {
      types: [
        GROUP_PINS_FETCH_REQUEST,
        GROUP_PINS_FETCH_SUCCESS,
        GROUP_PINS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/groupPin'),
      schema: { data: [groupPinsSchema] },
    },
  };
}

export function modifyGroupPin(params) {
  return {
    [CALL_API]: {
      types: [
        GROUP_PIN_MODIFY_REQUEST,
        GROUP_PIN_MODIFY_SUCCESS,
        GROUP_PIN_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/groupPin/modify', params),
      successMessage: 'Successfully updated group PIN',
    },
  };
}

export function deleteGroupPin(id) {
  return {
    id,
    [CALL_API]: {
      types: [
        GROUP_PIN_DELETE_REQUEST,
        GROUP_PIN_DELETE_SUCCESS,
        GROUP_PIN_DELETE_ERROR,
      ],
      promise: (httpClient) => httpClient.delete(`/groupPin/${id}`),
      successMessage: 'Successfully deleted group PIN',
    },
  };
}
