import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const EPD_UNITS_FETCH_REQUEST = 'epdUnits/EPD_UNITS_FETCH_REQUEST';
export const EPD_UNITS_FETCH_SUCCESS = 'epdUnits/EPD_UNITS_FETCH_SUCCESS';
export const EPD_UNITS_FETCH_ERROR = 'epdUnits/EPD_UNITS_FETCH_ERROR';

export const EPD_UNITS_UPDATE_REQUEST = 'epdUnits/EPD_UNITS_UPDATE_REQUEST';
export const EPD_UNITS_UPDATE_SUCCESS = 'epdUnits/EPD_UNITS_UPDATE_SUCCESS';
export const EPD_UNITS_UPDATE_ERROR = 'epdUnits/EPD_UNITS_UPDATE_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case EPD_UNITS_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case EPD_UNITS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.epdUnits,
        result: action.payload.result,
      };
    default:
      return {
        ...state,
      };
  }
}

// Schemas
const epdUnitSchema = new schema.Entity('epdUnits');

// Operations
export function fetchEpdUnits() {
  return {
    [CALL_API]: {
      types: [
        EPD_UNITS_FETCH_REQUEST,
        EPD_UNITS_FETCH_SUCCESS,
        EPD_UNITS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/epdType'),
      schema: { data: [epdUnitSchema] },
    },
  };
}

export function updateEpdUnit(params) {
  return {
    [CALL_API]: {
      types: [
        EPD_UNITS_UPDATE_REQUEST,
        EPD_UNITS_UPDATE_SUCCESS,
        EPD_UNITS_UPDATE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/epdType/modify', params),
      schema: { data: [epdUnitSchema] },
      successMessage: 'EPD Unit successfully updated',
    },
  };
}
