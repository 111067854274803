import { createMuiTheme } from '@material-ui/core';

export const red = '#ED1B2D';
export const yellow = '#FF8F00';
export const black = '#221E1F';

const white = {
  main: '#fff',
  contrastText: '#0E2B4D',
};
const navy = {
  light: '#99A8BD',
  main: '#0E2B4D',
  contrastText: '#fff',
};

// text for left menu items
export const drawerTextColor = white.main;
// text for top menu icons
export const headerIconColor = white.main;

const theme = createMuiTheme({
  palette: {
    primary: navy,
    secondary: navy,
    text: {
      // primary: white.main,
      secondary: navy.main,
    },
    background: {
      paper: '#fff',
      default: '#0E2B4D',
    },
    action: {
      active: navy.main,
    },
    // type: 'light',
  },
  typography: {
    fontFamily: [
      'Barlow',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 14,
  },
  shape: {
    borderRadius: '11px',
  },
  overrides: {
    MuiTextField: {
      root: {
        margin: '0.9rem 0',
      },
    },
    MuiSelect: {
      select: {
        fontSize: 14,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${navy.light}`,
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '4px 8px 20px 0 rgba(0, 0, 0, 0.26)',
      },
    },
  },
});

export default {
  ...theme,
  drawerTextColor,
  headerIconColor,
};
