import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const COURIERS_FETCH_REQUEST = 'couriers/COURIERS_FETCH_REQUEST';
export const COURIERS_FETCH_SUCCESS = 'couriers/COURIERS_FETCH_SUCCESS';
export const COURIERS_FETCH_ERROR = 'couriers/COURIERS_FETCH_ERROR';

export const COURIER_FETCH_REQUEST = 'couriers/COURIER_FETCH_REQUEST';
export const COURIER_FETCH_SUCCESS = 'couriers/COURIER_FETCH_SUCCESS';
export const COURIER_FETCH_ERROR = 'couriers/COURIER_FETCH_ERROR';

export const COURIER_UNLOAD_CURRENT = 'couriers/COURIER_UNLOAD_CURRENT';

export const COURIER_CREATE_REQUEST = 'couriers/COURIER_CREATE_REQUEST';
export const COURIER_CREATE_SUCCESS = 'couriers/COURIER_CREATE_SUCCESS';
export const COURIER_CREATE_ERROR = 'couriers/COURIER_CREATE_ERROR';

export const COURIER_DELETE_REQUEST = 'couriers/COURIER_DELETE_REQUEST';
export const COURIER_DELETE_SUCCESS = 'couriers/COURIER_DELETE_SUCCESS';
export const COURIER_DELETE_ERROR = 'couriers/COURIER_DELETE_ERROR';

export const COURIER_MODIFY_REQUEST = 'couriers/COURIER_MODIFY_REQUEST';
export const COURIER_MODIFY_SUCCESS = 'couriers/COURIER_MODIFY_SUCCESS';
export const COURIER_MODIFY_ERROR = 'couriers/COURIER_MODIFY_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case COURIERS_FETCH_REQUEST:
    case COURIER_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case COURIERS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.courier,
        result: action.payload.result,
      };

    case COURIER_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case COURIER_MODIFY_SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, [action.payload.id]: action.payload },
      };

    case COURIERS_FETCH_ERROR:
    case COURIER_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case COURIER_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
}

// Schemas
const courierSchema = new schema.Entity('courier');

// Operations

export function fetchCouriers(params) {
  return {
    [CALL_API]: {
      types: [
        COURIERS_FETCH_REQUEST,
        COURIERS_FETCH_SUCCESS,
        COURIERS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/courier', params),
      schema: { data: [courierSchema] },
    },
  };
}

export function fetchCourier(id) {
  return function (dispatch, getState) {
    const couriersState = getState().couriers;

    if (couriersState.entities && id in couriersState.entities) {
      // courier already exists in store
      dispatch({
        type: COURIER_FETCH_SUCCESS,
        payload: couriersState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            COURIER_FETCH_REQUEST,
            COURIER_FETCH_SUCCESS,
            COURIER_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/courier/${id}`),
        },
      });
    }
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: COURIER_UNLOAD_CURRENT });
  };
}

export function modifyCourier({ courier, successMessage, errorMessage }) {
  return {
    [CALL_API]: {
      types: [
        COURIER_MODIFY_REQUEST,
        COURIER_MODIFY_SUCCESS,
        COURIER_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/courier/modify', courier),
      successMessage: successMessage || 'Courier successfully modified',
      errorMessage: errorMessage || 'There was a problem modifying the courier',
    },
  };
}

export function createCourier({
  courier,
  successMessage,
  errorMessage,
  onSuccess,
}) {
  return {
    [CALL_API]: {
      types: [
        COURIER_CREATE_REQUEST,
        COURIER_CREATE_SUCCESS,
        COURIER_CREATE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/courier/modify', courier),
      successMessage: successMessage || 'Courier successfully created',
      errorMessage: errorMessage || 'There was a problem creating the courier',
      onSuccess,
    },
  };
}

export function regenerateAccessCode({
  id,
  successMessage,
  errorMessage,
  onSuccess = () => null,
}) {
  return {
    [CALL_API]: {
      types: [
        COURIER_MODIFY_REQUEST,
        COURIER_MODIFY_SUCCESS,
        COURIER_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.get(`/courier/regenerate/${id}/code`),
      successMessage: successMessage || 'Access code successfully regenerated',
      errorMessage:
        errorMessage || 'There was a problem regenerating an access code',
      onSuccess,
    },
  };
}

export function deleteCourier({ id, successMessage, errorMessage, onSuccess }) {
  return {
    [CALL_API]: {
      types: [
        COURIER_DELETE_REQUEST,
        COURIER_DELETE_SUCCESS,
        COURIER_DELETE_ERROR,
      ],
      promise: (httpClient) => httpClient.delete(`/courier/${id}/delete`),
      successMessage: successMessage || 'Courier successfully deleted',
      errorMessage: errorMessage || 'There was a problem deleting the courier',
      onSuccess: onSuccess || (() => null),
    },
  };
}
