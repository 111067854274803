export const SITE_TITLE = 'Huber';
export const API_URL = '/api';
export const LOG_MISSING_TRANSLATIONS = false;

export const LOCALE_URL = ['production', 'test'].includes(process.env.NODE_ENV)
  ? '/locales'
  : 'http://localhost:3333/locales';

export const ACCOUNT_ROLE_ENUMS = ['ADMIN', 'SUPER_ADMIN', 'MODERATOR'];

export const ACCOUNT_STATUS_ENUMS = ['ACTIVE', 'INACTIVE', 'INVITED'];

export const EVENT_SOURCE_ENUMS = ['APP', 'LORA'];

export const ACTION_ENUMS = [];

export const EVENT_STATUS_ENUMS = ['SUCCESS', 'FAIL_KEY_INVALID', 'FAIL_OTHER'];

export const OPEN_LOCKER_STATUS = [
  'DELIVERY',
  'PICKUP',
  'SEND_CREATE',
  'SEND_PICKUP',
  'SEND_CANCEL',
  'FORCE_OPENED',
  'MECHANICAL_OPEN',
  'DELIVERY_BY_GROUP',
];

export const NETWORK_BAND = [
  'None',
  'B1',
  'B2',
  'B3',
  'B4',
  'B5',
  'B8',
  'B12',
  'B13',
  'B18',
  'B19',
  'B20',
  'B26',
  'B28',
];

export const MODEM_STATUS = ['UNKNOWN', 'TURNED_OFF', 'POWER_SAVING', 'SLEEP'];

export const NETWORK_MODES = ['GSM', 'NB1', 'CATM1'];

export const GOOGLE_MAP_API_KEY = 'AIzaSyCE_hqyzF4Zm4T3py8ieljazaeyiwa678A';

export const ERRORS = {
  100: 'REQUEST_MISSING_REQUIRED_FIELDS',
  101: 'REQUEST_INVALID_VALUE',
  110: 'REQUEST_UNAUTHORIZED',
  120: 'REQUEST_UPLOAD_ERROR',
  121: 'REQUEST_DOWNLOAD_ERROR',
  1101: 'DATA_ACCOUNT_USERNAME_DUPLICATE',
  1102: 'DATA_ACCOUNT_USERNAME_INVALID',
  1103: 'DATA_ACCOUNT_PASSWORD_INVALID',
  1200: 'DATA_ENDUSER_NOTFOUND',
  1201: 'DATA_ENDUSER_USERNAME_REQUIRED',
  1202: 'DATA_ENDUSER_USERNAME_INVALID',
  1203: 'DATA_ENDUSER_USERNAME_DUPLICATE',
  1204: 'DATA_ENDUSER_EMAIL_DUPLICATE',
  1205: 'DATA_ENDUSER_EMAIL_REQUIRED',
  1206: 'DATA_ENDUSER_PASSWORD_REQUIRED',
  1207: 'DATA_ENDUSER_PASSWORD_INVALID',
  1208: 'DATA_ENDUSER_FIRSTNAME_REQUIRED',
  1209: 'DATA_ENDUSER_LASTNAME_REQUIRED',
  1210: 'DATA_ENDUSER_LANGUAGE_REQUIRED',
  1211: 'DATA_ENDUSER_REGISTER_ID_NOT_REQUIRED',
  1212: 'DATA_ENDUSER_NOTIFICATION_OPTIONS_INVALID',
  9998: 'RESOURCE_UNAVAILABLE',
  9999: 'GENERIC',
};

export const RESET_REASON = [
  'POWER_ON',
  'PIN_RESET',
  'BROWN_OUT',
  'SOFTWARE',
  'WATCHDOG',
  'LOCKUP',
  'WAKE_LOW_POWER',
  'ACCESS_ERROR',
  'BOOT_ERROR',
  'MULTIPLE',
  'PLATFORM',
  'UNKNOWN',
];

export const IS_CPL =
  process.env.REACT_APP_PROJECT === 'flexicpl' ||
  process.env.REACT_APP_PROJECT === 'smartboxbasel';
