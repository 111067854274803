import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import LeftDrawer from './LeftDrawer';
import brands from '../../brands';
import menuList from '../../menuList';

const { backgroundImage } = brands[process.env.REACT_APP_PROJECT];

const Container = styled.div`
  margin: 20px 20px 20px 15px;
  padding-left: ${(props) => `${props.paddingLeft}px`};
`;

const Background = styled.div`
  position: fixed;
  min-width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-image: url(${backgroundImage});
  background-size: cover;
  z-index: -9999;
`;

export default function Layout({ children }) {
  const { t } = useTranslation();
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector((state) => state.auth.current);

  useEffect(() => {
    if (isSmallScreen) {
      setIsNavDrawerOpen(false);
    } else {
      setIsNavDrawerOpen(true);
    }
  }, [isSmallScreen]);

  const toggleNavDraw = () => setIsNavDrawerOpen((prev) => !prev);
  const marginLeftDrawerOpen = 230;

  const menus = menuList
    .filter((item) => {
      // is menu item for current user role?
      if (!item.restrict) return true;
      const { role } = currentUser;
      if (item.restrict.includes(role)) return true;
      return false;
    })
    .filter((item) => {
      // is menu item for current brand build?
      if (
        item.projects.some(
          (project) => project === process.env.REACT_APP_PROJECT
        )
      )
        return true;
      return false;
    })
    .map((menuItem) => ({ ...menuItem, text: t(menuItem.text) }));

  return (
    <div>
      <Background />
      <div>
        <Header
          style={{
            marginLeft:
              isNavDrawerOpen && !isSmallScreen ? marginLeftDrawerOpen : 0,
          }}
          navDrawerOpen={isNavDrawerOpen}
          user={currentUser}
          handleChangeRequestNavDrawer={toggleNavDraw}
        />
        <LeftDrawer
          onToggle={toggleNavDraw}
          isSmallScreen={isSmallScreen}
          isOpen={isNavDrawerOpen}
          menus={menus}
        />
        <Container
          paddingLeft={
            isNavDrawerOpen && !isSmallScreen ? marginLeftDrawerOpen : 0
          }
        >
          {children}
        </Container>
      </div>
    </div>
  );
}
