import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const MESSAGE_LOG_FETCH_REQUEST = 'messageLog/MESSAGE_LOG_FETCH_REQUEST';
export const MESSAGE_LOG_FETCH_SUCCESS =
  'messageLog/MESSAGE_LOG_FETCHED_SUCCESS';
export const MESSAGE_LOG_FETCH_ERROR = 'messageLog/MESSAGE_LOG_FETCHED_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case MESSAGE_LOG_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case MESSAGE_LOG_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.messageLog,
        result: action.payload.result,
      };

    case MESSAGE_LOG_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Schemas
export const messageLogSchema = new schema.Entity('messageLog');

// Operations
export function fetchMessageLogs(params) {
  return {
    [CALL_API]: {
      types: [
        MESSAGE_LOG_FETCH_REQUEST,
        MESSAGE_LOG_FETCH_SUCCESS,
        MESSAGE_LOG_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/messageLog', params),
      schema: { data: [messageLogSchema] },
    },
  };
}
