import huber from './huber';
import zwick from './zwick';
import flexilocker from './flexilocker';
import smartboxbasel from './smartboxbasel';

export default {
  huber,
  zwick,
  flexilocker,
  smartboxbasel,
  flexiretail: flexilocker,
  flexicpl: flexilocker,
};
