import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const FETCH_REQUEST = 'mailCodes/FETCH_REQUEST';
export const FETCH_SUCCESS = 'mailCodes/FETCH_SUCCESS';
export const FETCH_ERROR = 'mailCodes/FETCH_ERROR';

export const SINGLE_FETCH_REQUEST = 'mailCodes/SINGLE_FETCH_REQUEST';
export const SINGLE_FETCH_SUCCESS = 'mailCodes/SINGLE_FETCH_SUCCESS';
export const SINGLE_FETCH_ERROR = 'mailCodes/SINGLE_FETCH_ERROR';

export const UNLOAD_CURRENT = 'mailCodes/UNLOAD_CURRENT';

export const CREATE_REQUEST = 'mailCodes/CREATE_REQUEST';
export const CREATE_SUCCESS = 'mailCodes/CREATE_SUCCESS';
export const CREATE_ERROR = 'mailCodes/CREATE_ERROR';

export const DELETE_REQUEST = 'mailCodes/DELETE_REQUEST';
export const DELETE_SUCCESS = 'mailCodes/DELETE_SUCCESS';
export const DELETE_ERROR = 'mailCodes/DELETE_ERROR';

export const MODIFY_REQUEST = 'mailCodes/MODIFY_REQUEST';
export const MODIFY_SUCCESS = 'mailCodes/MODIFY_SUCCESS';
export const MODIFY_ERROR = 'mailCodes/MODIFY_ERROR';

// REDUCER
export default function reducer(
  state = {
    masterLocations: null,
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case FETCH_REQUEST:
    case SINGLE_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.mailCodes,
        result: action.payload.result,
      };

    case SINGLE_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case MODIFY_SUCCESS:
    case CREATE_SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, [action.payload.id]: action.payload },
      };

    case FETCH_ERROR:
    case SINGLE_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    case DELETE_SUCCESS:
      const newEntities = { ...state.entities };
      delete newEntities[action.payload.id];
      return {
        ...state,
        entities: newEntities,
      };
    default:
      return state;
  }
}

// Schemas

const courierSchema = new schema.Entity('mailCodes');

// Operations

export function fetchMailCodes() {
  return {
    [CALL_API]: {
      types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_ERROR],
      promise: (httpClient) => httpClient.post('endUser/mailCode'),
      schema: { data: [courierSchema] },
    },
  };
}

export function updateMailCodeStatus({
  mailCode,
  onSuccess,
  successMessage,
  errorMessage,
}) {
  return {
    [CALL_API]: {
      types: [MODIFY_REQUEST, MODIFY_SUCCESS, MODIFY_ERROR],
      promise: (httpClient) =>
        httpClient.put('endUser/mailCode/status/update', mailCode),
      successMessage: successMessage || 'Mail code update',
      errorMessage: errorMessage || 'There was a problem updating the code',
      onSuccess,
    },
  };
}
