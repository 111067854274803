import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const COLLECTORS_FETCH_REQUEST = 'collectors/COLLECTORS_FETCH_REQUEST';
export const COLLECTORS_FETCH_SUCCESS = 'collectors/COLLECTORS_FETCH_SUCCESS';
export const COLLECTORS_FETCH_ERROR = 'collectors/COLLECTORS_FETCH_ERROR';

export const COLLECTOR_FETCH_REQUEST = 'collectors/COLLECTOR_FETCH_REQUEST';
export const COLLECTOR_FETCH_SUCCESS = 'collectors/COLLECTOR_FETCH_SUCCESS';
export const COLLECTOR_FETCH_ERROR = 'collectors/COLLECTOR_FETCH_ERROR';

export const COLLECTOR_UNLOAD_CURRENT = 'collectors/COLLECTOR_UNLOAD_CURRENT';

export const COLLECTOR_CREATE_REQUEST = 'collectors/COLLECTOR_CREATE_REQUEST';
export const COLLECTOR_CREATE_SUCCESS = 'collectors/COLLECTOR_CREATE_SUCCESS';
export const COLLECTOR_CREATE_ERROR = 'collectors/COLLECTOR_CREATE_ERROR';

export const COLLECTOR_DELETE_REQUEST = 'collectors/COLLECTOR_DELETE_REQUEST';
export const COLLECTOR_DELETE_SUCCESS = 'collectors/COLLECTOR_DELETE_SUCCESS';
export const COLLECTOR_DELETE_ERROR = 'collectors/COLLECTOR_DELETE_ERROR';

export const COLLECTOR_MODIFY_REQUEST = 'collectors/COLLECTOR_MODIFY_REQUEST';
export const COLLECTOR_MODIFY_SUCCESS = 'collectors/COLLECTOR_MODIFY_SUCCESS';
export const COLLECTOR_MODIFY_ERROR = 'collectors/COLLECTOR_MODIFY_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case COLLECTORS_FETCH_REQUEST:
    case COLLECTOR_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case COLLECTORS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.collector,
        result: action.payload.result,
      };

    case COLLECTOR_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case COLLECTOR_MODIFY_SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, [action.payload.id]: action.payload },
        current: action.payload,
      };

    case COLLECTORS_FETCH_ERROR:
    case COLLECTOR_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case COLLECTOR_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };

    default:
      return state;
  }
}

// Schemas

const collectorSchema = new schema.Entity('collector');

// Operations

export function fetchCollectors(params) {
  return {
    [CALL_API]: {
      types: [
        COLLECTORS_FETCH_REQUEST,
        COLLECTORS_FETCH_SUCCESS,
        COLLECTORS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/collector', params),
      schema: { data: [collectorSchema] },
    },
  };
}

export function fetchCollector(id) {
  return function (dispatch, getState) {
    const collectorState = getState().collector;

    if (collectorState.entities && id in collectorState.entities) {
      // collector already exists in store
      dispatch({
        type: COLLECTOR_FETCH_SUCCESS,
        payload: collectorState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            COLLECTOR_FETCH_REQUEST,
            COLLECTOR_FETCH_SUCCESS,
            COLLECTOR_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/collector/${id}`),
        },
      });
    }
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: COLLECTOR_UNLOAD_CURRENT });
  };
}

export function modifyCollector({ collector, successMessage, errorMessage }) {
  return {
    [CALL_API]: {
      types: [
        COLLECTOR_MODIFY_REQUEST,
        COLLECTOR_MODIFY_SUCCESS,
        COLLECTOR_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/collector/modify', collector),
      successMessage: successMessage || 'Collector successfully modified',
      errorMessage:
        errorMessage || 'There was a problem modifying the collector',
    },
  };
}

export function createCollector({
  collector,
  successMessage,
  errorMessage,
  onSuccess,
}) {
  return {
    [CALL_API]: {
      types: [
        COLLECTOR_CREATE_REQUEST,
        COLLECTOR_CREATE_SUCCESS,
        COLLECTOR_CREATE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/collector/modify', collector),
      successMessage: successMessage || 'Collector successfully created',
      errorMessage:
        errorMessage || 'There was a problem creating the collector',
      onSuccess,
    },
  };
}

export function deleteCollector({
  id,
  successMessage,
  errorMessage,
  onSuccess,
}) {
  return {
    [CALL_API]: {
      types: [
        COLLECTOR_DELETE_REQUEST,
        COLLECTOR_DELETE_SUCCESS,
        COLLECTOR_DELETE_ERROR,
      ],
      promise: (httpClient) => httpClient.delete(`/collector/${id}/delete`),
      successMessage: successMessage || 'Collector successfully deleted',
      errorMessage:
        errorMessage || 'There was a problem deleting the collector',
      onSuccess: onSuccess || (() => null),
    },
  };
}
