import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const FETCH_REQUEST = 'courierMasterLocation/FETCH_REQUEST';
export const FETCH_SUCCESS = 'courierMasterLocation/FETCH_SUCCESS';
export const FETCH_ERROR = 'courierMasterLocation/FETCH_ERROR';

export const SINGLE_FETCH_REQUEST =
  'courierMasterLocation/SINGLE_FETCH_REQUEST';
export const SINGLE_FETCH_SUCCESS =
  'courierMasterLocation/SINGLE_FETCH_SUCCESS';
export const SINGLE_FETCH_ERROR = 'courierMasterLocation/SINGLE_FETCH_ERROR';

export const UNLOAD_CURRENT = 'courierMasterLocation/UNLOAD_CURRENT';

export const CREATE_REQUEST = 'courierMasterLocation/CREATE_REQUEST';
export const CREATE_SUCCESS = 'courierMasterLocation/CREATE_SUCCESS';
export const CREATE_ERROR = 'courierMasterLocation/CREATE_ERROR';

export const DELETE_REQUEST = 'courierMasterLocation/DELETE_REQUEST';
export const DELETE_SUCCESS = 'courierMasterLocation/DELETE_SUCCESS';
export const DELETE_ERROR = 'courierMasterLocation/DELETE_ERROR';

export const MODIFY_REQUEST = 'courierMasterLocation/MODIFY_REQUEST';
export const MODIFY_SUCCESS = 'courierMasterLocation/MODIFY_SUCCESS';
export const MODIFY_ERROR = 'courierMasterLocation/MODIFY_ERROR';

// REDUCER
export default function reducer(
  state = {
    masterLocations: null,
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case FETCH_REQUEST:
    case SINGLE_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.courierMasterLocation,
        result: action.payload.result,
      };

    case SINGLE_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case MODIFY_SUCCESS:
    case CREATE_SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, [action.payload.id]: action.payload },
      };

    case FETCH_ERROR:
    case SINGLE_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    case DELETE_SUCCESS:
      const newEntities = { ...state.entities };
      delete newEntities[action.payload.id];
      return {
        ...state,
        entities: newEntities,
      };
    default:
      return state;
  }
}

// Schemas

const courierSchema = new schema.Entity('courierMasterLocation');

// Operations

export function fetchCourierMasterLocations() {
  return {
    [CALL_API]: {
      types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_ERROR],
      promise: (httpClient) => httpClient.post('/masterCourierPickupLocation'),
      schema: { data: [courierSchema] },
    },
  };
}

export function fetchCourierMasterLocation(id) {
  return function (dispatch, getState) {
    const courierMasterLocationState = getState().courierMasterLocation;

    if (
      courierMasterLocationState.entities &&
      id in courierMasterLocationState.entities
    ) {
      // courier already exists in store
      dispatch({
        type: SINGLE_FETCH_SUCCESS,
        payload: courierMasterLocationState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            SINGLE_FETCH_REQUEST,
            SINGLE_FETCH_SUCCESS,
            SINGLE_FETCH_ERROR,
          ],
          promise: (httpClient) =>
            httpClient.get(`/masterCourierPickupLocation/${id}`),
        },
      });
    }
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: UNLOAD_CURRENT });
  };
}

export function modifyCourierMasterLocation({
  courierMasterLocation,
  onSuccess,
  successMessage,
  errorMessage,
}) {
  return {
    [CALL_API]: {
      types: [MODIFY_REQUEST, MODIFY_SUCCESS, MODIFY_ERROR],
      promise: (httpClient) =>
        httpClient.post(
          '/masterCourierPickupLocation/modify',
          courierMasterLocation
        ),
      successMessage: successMessage || 'Courier address successfully modified',
      errorMessage: errorMessage || 'There was a problem updating the address',
      onSuccess,
    },
  };
}

export function createCourierMasterLocation({
  courierMasterLocation,
  successMessage = 'Courier master location successfully created',
  errorMessage = 'There was a problem deleting the courier master location',
}) {
  return {
    [CALL_API]: {
      types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_ERROR],
      promise: (httpClient) =>
        httpClient.post(
          '/masterCourierPickupLocation/modify',
          courierMasterLocation
        ),
      successMessage,
      errorMessage,
    },
  };
}

export function deleteCourierMasterLocation({
  id,
  successMessage,
  errorMessage,
  onSuccess,
}) {
  return {
    [CALL_API]: {
      types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_ERROR],
      promise: (httpClient) =>
        httpClient.delete(`/masterCourierPickupLocation/${id}/delete`),
      successMessage: successMessage || 'Courier address successfully deleted',
      errorMessage: errorMessage || 'There was a problem deleting the courier',
      onSuccess: onSuccess || (() => null),
    },
  };
}
