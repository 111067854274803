import { CALL_API } from '../middleware/api';

// ACTIONS

export const MESSAGES_FETCH_REQUEST = 'accounts/MESSAGES_FETCH_REQUEST';
export const MESSAGES_FETCH_SUCCESS = 'accounts/MESSAGES_FETCHED_SUCCESS';
export const MESSAGES_FETCH_ERROR = 'accounts/MESSAGES_FETCHED_ERROR';

export const MESSAGES_MODIFY_REQUEST = 'accounts/MESSAGES_MODIFY_REQUEST';
export const MESSAGES_MODIFY_SUCCESS = 'accounts/MESSAGES_MODIFY_SUCCESS';
export const MESSAGES_MODIFY_ERROR = 'accounts/MESSAGES_MODIFY_ERROR';

export const MESSAGE_MODIFY_REQUEST = 'accounts/MESSAGE_MODIFY_REQUEST';
export const MESSAGE_MODIFY_SUCCESS = 'accounts/MESSAGE_MODIFY_SUCCESS';
export const MESSAGE_MODIFY_ERROR = 'accounts/MESSAGE_MODIFY_ERROR';

export const MESSAGES_FETCH_LANG_REQUEST =
  'accounts/MESSAGES_FETCH_LANG_REQUEST';
export const MESSAGES_FETCH_LANG_SUCCESS =
  'accounts/MESSAGES_FETCH_LANG_SUCCESS';
export const MESSAGES_FETCH_LANG_ERROR = 'accounts/MESSAGES_FETCH_LANG_ERROR';

export const MESSAGES_FETCH_MSG_TYPE_REQUEST =
  'accounts/MESSAGES_FETCH_MSG_TYPE_REQUEST';
export const MESSAGES_FETCH_MSG_TYPE_SUCCESS =
  'accounts/MESSAGES_FETCH_MSG_TYPE_SUCCESS';
export const MESSAGES_FETCH_MSG_TYPE_ERROR =
  'accounts/MESSAGES_FETCH_MSG_TYPE_ERROR';

// REDUCER
export default function reducer(
  state = {
    messages: [],
    messagesModifySuccess: null,
    fetching: false,
    fetched: false,
    languages: [],
    messageTypes: [],
    modified: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case MESSAGES_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case MESSAGES_FETCH_SUCCESS: {
      const messages = action.payload.data.sort((a, b) => a.id - b.id);

      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        messages,
      });
    }

    case MESSAGES_FETCH_LANG_SUCCESS: {
      const languages = action.payload.data.sort((a, b) => a.id - b.id);
      return {
        ...state,
        languages,
      };
    }

    case MESSAGES_FETCH_MSG_TYPE_SUCCESS: {
      return {
        ...state,
        messageTypes: action.payload.data,
      };
    }

    case MESSAGES_MODIFY_REQUEST: {
      const newState = {};
      if (action.group === 'PAKETBOX') {
        newState.paketboxModifySuccess = null;
      } else if (action.group === 'TERMS_AND_CONDITIONS') {
        newState.termsModifySuccess = null;
      } else {
        newState.otherModifySuccess = null;
      }

      return Object.assign({}, state, newState);
    }

    case MESSAGES_MODIFY_SUCCESS: {
      return Object.assign({}, state, { messagesModifySuccess: true });
    }

    case MESSAGES_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Operations
export function fetchMessages() {
  return {
    [CALL_API]: {
      types: [
        MESSAGES_FETCH_REQUEST,
        MESSAGES_FETCH_SUCCESS,
        MESSAGES_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/message'),
    },
  };
}

export function fetchLanguages() {
  return {
    [CALL_API]: {
      types: [
        MESSAGES_FETCH_LANG_REQUEST,
        MESSAGES_FETCH_LANG_SUCCESS,
        MESSAGES_FETCH_LANG_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/language'),
      //   schema: { data: [languageSchema] },
    },
  };
}

export function fetchMessageTypes() {
  return {
    [CALL_API]: {
      types: [
        MESSAGES_FETCH_MSG_TYPE_REQUEST,
        MESSAGES_FETCH_MSG_TYPE_SUCCESS,
        MESSAGES_FETCH_MSG_TYPE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/messageType'),
      //    schema: { data: [messageTypeSchema] },
    },
  };
}

export function modifyMessages(messages) {
  return {
    [CALL_API]: {
      types: [
        MESSAGES_MODIFY_REQUEST,
        MESSAGES_MODIFY_SUCCESS,
        MESSAGES_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/message/modifyList', messages),
      successMessage: 'Messages successfully modified',
    },
  };
}

export function modifyMessage(message) {
  return {
    [CALL_API]: {
      types: [
        MESSAGE_MODIFY_REQUEST,
        MESSAGE_MODIFY_SUCCESS,
        MESSAGE_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/message', message),
      successMessage: 'Messages successfully modified',
    },
  };
}
