import React from 'react';
import styled from 'styled-components/macro';
import { CircularProgress } from '@material-ui/core';

const Container = styled.div`
  ${(props) =>
    props.isButton
      ? ''
      : 'min-width: 320px; max-width: 400px; margin: 2rem auto;'}
  text-align: center;
  height: auto;
  top: 20%;
  left: 0;
  right: 0;
`;

const Loading = ({ isButton }) => (
  <Container isButton={isButton}>
    <CircularProgress
      variant="indeterminate"
      color="secondary"
      size={isButton ? 18 : 40}
    />
  </Container>
);

export default Loading;
