import React from 'react';
import styled from 'styled-components/macro';
import {
  Menu,
  ListItemIcon,
  ListItemText,
  MenuItem,
  IconButton,
  Divider,
} from '@material-ui/core';
import i18n from 'translations/i18n';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logoutUser } from 'redux/modules/auth';
import enImg from 'assets/images/flags/en.png';
import deImg from 'assets/images/flags/de.png';
import frImg from 'assets/images/flags/fr.png';
import itImg from 'assets/images/flags/it.png';

const ImgStyled = styled.img`
  margin: 0 14px 0 0;
  width: auto;
  height: 20px;
`;

export default function UserMenu({ t, iconColor }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const changeLanguage = (lang) => {
    window.localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    history.push('/');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} style={{ color: iconColor, margin: 0 }}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            changeLanguage('en');
            handleClose();
          }}
        >
          <ListItemIcon>
            <ImgStyled src={enImg} />
          </ListItemIcon>
          <ListItemText>English</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeLanguage('de');
            handleClose();
          }}
        >
          <ListItemIcon>
            <ImgStyled src={deImg} />
          </ListItemIcon>
          <ListItemText>Deutsch</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeLanguage('fr');
            handleClose();
          }}
        >
          <ListItemIcon>
            <ImgStyled src={frImg} />
          </ListItemIcon>
          <ListItemText>Française</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeLanguage('it');
            handleClose();
          }}
        >
          <ListItemIcon>
            <ImgStyled src={itImg} />
          </ListItemIcon>
          <ListItemText>Italiano</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemText>{t('sign_out')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
