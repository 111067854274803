import backgroundImage from './bkg.jpg';
import logoLogin from './logo_login.png';
import logo from './logo.png';
import wordmark from './wordmark.png';
import favicon from './favicon.ico';

export default {
  backgroundImage,
  logoLogin,
  logo,
  wordmark,
  favicon,
};
