import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS

export const FETCH_MASTER_COURIER_LOCATION_REQUEST =
  'courierAddress/FETCH_MASTER_COURIER_LOCATION_REQUEST';
export const FETCH_MASTER_COURIER_LOCATION_SUCCESS =
  'courierAddress/FETCH_MASTER_COURIER_LOCATION_SUCCESS';
export const FETCH_MASTER_COURIER_LOCATION_ERROR =
  'courierAddress/FETCH_MASTER_COURIER_LOCATION_ERROR';

export const FETCH_REQUEST = 'courierAddress/FETCH_REQUEST';
export const FETCH_SUCCESS = 'courierAddress/FETCH_SUCCESS';
export const FETCH_ERROR = 'courierAddress/FETCH_ERROR';

export const SINGLE_FETCH_REQUEST = 'courierAddress/SINGLE_FETCH_REQUEST';
export const SINGLE_FETCH_SUCCESS = 'courierAddress/SINGLE_FETCH_SUCCESS';
export const SINGLE_FETCH_ERROR = 'courierAddress/SINGLE_FETCH_ERROR';

export const UNLOAD_CURRENT = 'courierAddress/UNLOAD_CURRENT';

export const CREATE_REQUEST = 'courierAddress/CREATE_REQUEST';
export const CREATE_SUCCESS = 'courierAddress/CREATE_SUCCESS';
export const CREATE_ERROR = 'courierAddress/CREATE_ERROR';

export const DELETE_REQUEST = 'courierAddress/DELETE_REQUEST';
export const DELETE_SUCCESS = 'courierAddress/DELETE_SUCCESS';
export const DELETE_ERROR = 'courierAddress/DELETE_ERROR';

export const MODIFY_REQUEST = 'courierAddress/MODIFY_REQUEST';
export const MODIFY_SUCCESS = 'courierAddress/MODIFY_SUCCESS';
export const MODIFY_ERROR = 'courierAddress/MODIFY_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case FETCH_REQUEST:
    case SINGLE_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.courierAddress,
        result: action.payload.result,
      };

    case SINGLE_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case MODIFY_SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, [action.payload.id]: action.payload },
      };

    case FETCH_ERROR:
    case SINGLE_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
}

// Schemas

const courierSchema = new schema.Entity('courierAddress');

// Operations

export function fetchCourierAddresses(courierId) {
  return {
    [CALL_API]: {
      types: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_ERROR],
      promise: (httpClient) =>
        httpClient.post(
          '/courierAddress',
          courierId
            ? {
                filter: {
                  filters: [
                    {
                      field: 'courier___id',
                      value: Number(courierId),
                      operator: 'eq',
                    },
                  ],
                  logic: 'and',
                },
              }
            : {}
        ),
      schema: { data: [courierSchema] },
    },
  };
}

export function fetchCourierAddress(id) {
  return function (dispatch, getState) {
    const courierAddressState = getState().courierAddress;

    if (courierAddressState.entities && id in courierAddressState.entities) {
      // courier already exists in store
      dispatch({
        type: SINGLE_FETCH_SUCCESS,
        payload: courierAddressState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            SINGLE_FETCH_REQUEST,
            SINGLE_FETCH_SUCCESS,
            SINGLE_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/courierAddress/${id}`),
        },
      });
    }
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: UNLOAD_CURRENT });
  };
}

export function modifyCourierAddress({
  courierAddress,
  onSuccess,
  successMessage,
  errorMessage,
}) {
  return {
    [CALL_API]: {
      types: [MODIFY_REQUEST, MODIFY_SUCCESS, MODIFY_ERROR],
      promise: (httpClient) =>
        httpClient.post('/courierAddress/modify', courierAddress),
      successMessage: successMessage || 'Courier address successfully modified',
      errorMessage: errorMessage || 'There was a problem updating the address',
      onSuccess,
    },
  };
}

export function createCourierAddress(courier) {
  return {
    [CALL_API]: {
      types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_ERROR],
      promise: (httpClient) =>
        httpClient.post('/courierAddress/modify', courier),
      successMessage: 'Courier address successfully created',
    },
  };
}

export function deleteCourierAddress({
  id,
  successMessage,
  errorMessage,
  onSuccess,
}) {
  return {
    [CALL_API]: {
      types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_ERROR],
      promise: (httpClient) =>
        httpClient.delete(`/courierAddress/${id}/delete`),
      successMessage: successMessage || 'Courier address successfully deleted',
      errorMessage: errorMessage || 'There was a problem deleting the courier',
      onSuccess: onSuccess || (() => null),
    },
  };
}
