import { createMuiTheme } from '@material-ui/core';

export const useDrawer = true;

export const red = '#ED1B2D';
export const yellow = '#FF8F00';
export const black = '#221E1F';

// refresh button and coloured buttons
export const primary1Color = {
  main: '#E93947',
  contrastText: '#fff',
};
// subtitle backgrounds - should be darker
export const primary2Color = {
  main: '#8C8C8C',
  contrastText: '#fff',
};
// text for left menu items
export const drawerTextColor = 'rgba(255,255,255,0.9)';

// text for top menu icons
export const headerIconColor = 'rgba(255,255,255,0.9)';

const theme = createMuiTheme({
  palette: {
    primary: primary1Color,
    secondary: primary2Color,
    background: {
      paper: '#fff',
      default: '#4C4C4C',
    },
    type: 'light',
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
  },

  overrides: {
    MuiTextField: {
      root: {
        margin: '0.9rem 0',
      },
    },
    MuiSelect: {
      select: {
        fontSize: 14,
      },
    },
  },
});

export default {
  ...theme,
  drawerTextColor,
  headerIconColor,
};
