import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const LORA_CONFIG_FETCH_REQUEST =
  'modemConfigs/LORA_CONFIG_FETCH_REQUEST';
export const LORA_CONFIG_FETCH_SUCCESS =
  'modemConfigs/LORA_CONFIG_FETCHED_SUCCESS';
export const LORA_CONFIG_FETCH_ERROR = 'modemConfigs/LORA_CONFIG_FETCHED_ERROR';

export const LORA_CONFIGS_FETCH_REQUEST =
  'modemConfigs/LORA_CONFIGS_FETCH_REQUEST';
export const LORA_CONFIGS_FETCH_SUCCESS =
  'modemConfigs/LORA_CONFIGS_FETCHED_SUCCESS';
export const LORA_CONFIGS_FETCH_ERROR =
  'modemConfigs/LORA_CONFIGS_FETCHED_ERROR';

export const LORA_CONFIG_CREATE_REQUEST =
  'modemConfigs/LORA_CONFIG_CREATE_REQUEST';
export const LORA_CONFIG_CREATE_SUCCESS =
  'modemConfigs/LORA_CONFIG_CREATE_SUCCESS';
export const LORA_CONFIG_CREATE_ERROR = 'modemConfigs/LORA_CONFIG_CREATE_ERROR';

export const LORA_CONFIG_MODIFY_REQUEST =
  'modemConfigs/LORA_CONFIG_MODIFY_REQUEST';
export const LORA_CONFIG_MODIFY_SUCCESS =
  'modemConfigs/LORA_CONFIG_MODIFY_SUCCESS';
export const LORA_CONFIG_MODIFY_ERROR = 'modemConfigs/LORA_CONFIG_MODIFY_ERROR';

export const LORA_CONFIG_CLEAR_NEWLY_CREATED =
  'modemConfigs/LORA_CONFIG_CLEAR_NEWLY_CREATED';

export const LORA_CONFIG_UNLOAD_CURRENT =
  'modemConfigs/LORA_CONFIG_UNLOAD_CURRENT';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
    newlyCreatedId: null,
  },
  action
) {
  switch (action.type) {
    case LORA_CONFIGS_FETCH_REQUEST:
    case LORA_CONFIG_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case LORA_CONFIGS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.loraConfigs,
        result: action.payload.result,
      };
    case LORA_CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };
    case LORA_CONFIGS_FETCH_ERROR:
    case LORA_CONFIG_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case LORA_CONFIG_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };

    case LORA_CONFIG_CREATE_SUCCESS:
      return {
        ...state,
        newlyCreatedId: action.payload.id,
      };

    case LORA_CONFIG_CLEAR_NEWLY_CREATED:
      return {
        ...state,
        newlyCreatedId: null,
      };
    default:
      return state;
  }
}

// Schemas
const loraConfigSchema = new schema.Entity('modemConfigs');

// Operations
export function fetchLoraConfigs(params) {
  return {
    [CALL_API]: {
      types: [
        LORA_CONFIGS_FETCH_REQUEST,
        LORA_CONFIGS_FETCH_SUCCESS,
        LORA_CONFIGS_FETCH_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/lockers/paketbox/lora', params),
      schema: { data: [loraConfigSchema] },
    },
  };
}

export function fetchLoraConfig(id) {
  return function (dispatch, getState) {
    const loraConfigsState = getState().loraConfigs;

    if (loraConfigsState.entities && id in loraConfigsState.entities) {
      // loraConfig already exists in store
      dispatch({
        type: LORA_CONFIG_FETCH_SUCCESS,
        payload: loraConfigsState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            LORA_CONFIG_FETCH_REQUEST,
            LORA_CONFIG_FETCH_SUCCESS,
            LORA_CONFIG_FETCH_ERROR,
          ],
          promise: (httpClient) =>
            httpClient.get(`/lockers/paketbox/lora/${id}`),
        },
      });
    }
  };
}

export function unloadCurrentLoraConfig() {
  return function (dispatch) {
    dispatch({ type: LORA_CONFIG_UNLOAD_CURRENT });
  };
}

export function clearNewlyCreated() {
  return function (dispatch) {
    dispatch({ type: LORA_CONFIG_CLEAR_NEWLY_CREATED });
  };
}

export function createLoraConfig(loraConfig) {
  return {
    [CALL_API]: {
      types: [
        LORA_CONFIG_CREATE_REQUEST,
        LORA_CONFIG_CREATE_SUCCESS,
        LORA_CONFIG_CREATE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/lockers/paketbox/lora/modify', loraConfig),
      successMessage: 'LoRa Configuration successfully created',
    },
  };
}

export function modifyLoraConfig(loraConfig) {
  return {
    [CALL_API]: {
      types: [
        LORA_CONFIG_MODIFY_REQUEST,
        LORA_CONFIG_MODIFY_SUCCESS,
        LORA_CONFIG_MODIFY_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/lockers/paketbox/lora/modify', loraConfig),
      successMessage: 'LoRa Configuration successfully modified',
    },
  };
}
