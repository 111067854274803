import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from 'components/layout';
import Loading from 'components/shared/Loading';
import { useSelector } from 'react-redux';

// TODO: split this up into multiple routes files, every page could have it's own subroutes switch

const LockerUnitList = lazy(() => import('components/pages/LockerUnits'));
const MasterConfiguration = lazy(() =>
  import('components/pages/LockerUnits/Configuration')
);
const EpdUnitList = lazy(() => import('components/pages/EpdUnits'));

const AccountList = lazy(() => import('components/pages/Accounts'));
const AccountCreate = lazy(() =>
  import('components/pages/Accounts/Form/AccountCreate')
);
const AccountModify = lazy(() =>
  import('components/pages/Accounts/Form/AccountModify')
);

const EndUsers = lazy(() => import('components/pages/EndUsers'));
const EndUserModify = lazy(() =>
  import('components/pages/EndUsers/Form/EndUserModify')
);
const EndUserDevices = lazy(() =>
  import('components/pages/EndUsers/DeviceList')
);
const EndUserGroups = lazy(() => import('components/pages/EndUsers/GroupList'));

const GroupList = lazy(() => import('components/pages/Groups'));
const KeyList = lazy(() => import('components/pages/Keys'));
const Authorizations = lazy(() => import('components/pages/Authorizations'));
const PinManagementList = lazy(() => import('components/pages/PinManagement'));

const AlertLogs = lazy(() => import('components/pages/AlertLogs'));
const AlertText = lazy(() => import('components/pages/AlertText'));
const AlertTypeList = lazy(() => import('components/pages/AlertTypes'));
const AlertTypeModify = lazy(() =>
  import('components/pages/AlertTypes/Form/AlertTypeModify')
);

const NetworkConfigurationsList = lazy(() =>
  import('components/pages/NetworkConfigs')
);
const NetworkConfigurationsModify = lazy(() =>
  import('components/pages/NetworkConfigs/Form/NetworkConfigurationsModify')
);
const NetworkConfigurationsCreate = lazy(() =>
  import('components/pages/NetworkConfigs/Form/NetworkConfigurationsCreate')
);

const CourierList = lazy(() => import('components/pages/Couriers/CourierList'));
const CourierForm = lazy(() => import('components/pages/Couriers/Form'));

const CustomerList = lazy(() =>
  import('components/pages/Customers/CustomerList')
);
const CustomerForm = lazy(() => import('components/pages/Customers/Form'));

const CollectorList = lazy(() =>
  import('components/pages/Collectors/CollectorList')
);
const CollectorForm = lazy(() => import('components/pages/Collectors/Form'));

const MailCodesList = lazy(() =>
  import('components/pages/MailCodes/MailCodesList')
);
const Statistics = lazy(() => import('components/pages/Statistics'));

const NotFound = lazy(() => import('components/pages/NotFoundPage'));

export default function Routes() {
  const role = useSelector((state) => state.auth.current.role);
  const currentProject = process.env.REACT_APP_PROJECT;
  let defaultUnitType = 'mpl';
  if (currentProject === 'smartboxbasel' || currentProject === 'flexicpl')
    defaultUnitType = 'cpl';
  if (currentProject === 'flexiretail') defaultUnitType = 'retail';
  return (
    <Router>
      <Layout>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Suspense fallback={<Loading />}>
                <LockerUnitList unitType={defaultUnitType} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/mpl-units"
            render={() => (
              <Suspense fallback={<Loading />}>
                <LockerUnitList unitType="mpl" />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/spl-units"
            render={() => (
              <Suspense fallback={<Loading />}>
                <LockerUnitList unitType="spl" />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/tablet-units"
            render={() => (
              <Suspense fallback={<Loading />}>
                <LockerUnitList unitType="tablet" />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/cpl-units"
            render={() => (
              <Suspense fallback={<Loading />}>
                <LockerUnitList unitType="cpl" />
              </Suspense>
            )}
          />
          {defaultUnitType === 'retail' && (
            <Route
              exact
              path="/retail-lockers"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <LockerUnitList unitType="retail" />
                </Suspense>
              )}
            />
          )}
          <Route
            exact
            path="/master/modify/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <MasterConfiguration />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/end-user"
            render={() => (
              <Suspense fallback={<Loading />}>
                <EndUsers />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/end-user/modify/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <EndUserModify />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/end-user/devices/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <EndUserDevices />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/end-user/groupMember/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <EndUserGroups />
              </Suspense>
            )}
          />

          <Route
            exact
            path="/keys"
            render={() => (
              <Suspense fallback={<Loading />}>
                <KeyList />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/keys/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <KeyList />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/alert-logs"
            render={() => (
              <Suspense fallback={<Loading />}>
                <AlertLogs />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/alert-types/modify/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <AlertTypeModify />
              </Suspense>
            )}
          />

          <Route
            exact
            path="/couriers"
            render={() => (
              <Suspense fallback={<Loading />}>
                <CourierList />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/couriers/modify/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <CourierForm isModify />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/couriers/new"
            render={() => (
              <Suspense fallback={<Loading />}>
                <CourierForm />
              </Suspense>
            )}
          />

          {/* RESTRICTED ROUTES */}

          {role !== 'MODERATOR' ? (
            <>
              <Route
                exact
                path="/groups"
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <GroupList />
                  </Suspense>
                )}
              />
              <Route
                exact
                path="/network-configurations"
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <NetworkConfigurationsList />
                  </Suspense>
                )}
              />
              <Route
                exact
                path="/network-configurations/modify/:id"
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <NetworkConfigurationsModify />
                  </Suspense>
                )}
              />
              <Route
                exact
                path="/network-configurations/new"
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <NetworkConfigurationsCreate />
                  </Suspense>
                )}
              />
              <Route
                exact
                path="/mail-codes"
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <MailCodesList />
                  </Suspense>
                )}
              />
              <Route
                exact
                path="/statistics"
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <Statistics />
                  </Suspense>
                )}
              />
              <Route
                exact
                path="/statistics/:timePeriod/:timeRange/:deviceList"
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <Statistics />
                  </Suspense>
                )}
              />
              {role === 'SUPER_ADMIN' ? (
                <>
                  <Route
                    exact
                    path="/epd-units"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <EpdUnitList />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/authorizations"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <Authorizations />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/pin-management"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <PinManagementList />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/alerts"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <AlertText />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/alert-types"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <AlertTypeList />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/customers"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <CustomerList />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/customers/modify/:id"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <CustomerForm isModify />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/customers/new"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <CustomerForm />
                      </Suspense>
                    )}
                  />

                  <Route
                    exact
                    path="/collectors"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <CollectorList />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/collectors/modify/:id"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <CollectorForm isModify />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/collectors/new"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <CollectorForm />
                      </Suspense>
                    )}
                  />

                  <Route
                    exact
                    path="/accounts"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <AccountList />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/accounts/new"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <AccountCreate />
                      </Suspense>
                    )}
                  />
                  <Route
                    exact
                    path="/accounts/modify/:id"
                    render={() => (
                      <Suspense fallback={<Loading />}>
                        <AccountModify />
                      </Suspense>
                    )}
                  />
                </>
              ) : null}
            </>
          ) : null}

          {/* FALLBACK 404 */}
          <Route
            render={() => (
              <Suspense fallback={<Loading />}>
                <NotFound />
              </Suspense>
            )}
          />
        </Switch>
      </Layout>
    </Router>
  );
}
