import { css } from 'styled-components/macro';
import 'typeface-barlow';

export default css`
  body {
    font-family: ${[
      'Barlow',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(',')};
    background-color: #0e2b4d;
  }
`;
