import { CALL_API } from '../middleware/api';

// ACTIONS
export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'auth/LOGOUT_ERROR';

export const UNAUTHORIZED = 'auth/UNAUTHORIZED';

// REDUCER
export default function reducer(
  state = {
    authenticating: true,
    authenticated: false,
    error: false,
    current: {},
  },
  action
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, authenticating: true, error: false };
    case LOGIN_ERROR:
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        current: {},
        error: {
          error: action.payload,
          userMessage: 'Invalid username or password.',
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        error: false,
        current: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        error: false,
        current: {},
      };
    case UNAUTHORIZED:
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        current: {},
        error: {
          error: action.payload,
          userMessage: action.unauthorizedMessage,
        },
      };
    default:
      return state;
  }
}

// Operations
export function checkAuth() {
  return {
    [CALL_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR],
      promise: (httpClient) => httpClient.get('/check-auth'),
    },
  };
}

export function loginUser(username, password) {
  return {
    [CALL_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR],
      promise: (httpClient) =>
        httpClient.post('/login', { username, password }),
      unauthorizedMessage: 'Invalid username or password!',
    },
  };
}

export function logoutUser() {
  return {
    [CALL_API]: {
      types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_ERROR],
      promise: (httpClient) => httpClient.get('/logout'),
    },
  };
}
