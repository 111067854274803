import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';
import omit from 'lodash.omit';

// ACTIONS

export const MASTER_FETCH_REQUEST = 'mpl/MASTER_FETCH_REQUEST';
export const MASTER_FETCH_SUCCESS = 'mpl/MASTER_FETCH_SUCCESS';
export const MASTER_FETCH_ERROR = 'mpl/MASTER_FETCH_ERROR';

export const MASTER_LOCKER_FETCH_REQUEST = 'mpl/MASTER_LOCKER_FETCH_REQUEST';
export const MASTER_LOCKER_FETCH_SUCCESS = 'mpl/MASTER_LOCKER_FETCH_SUCCESS';
export const MASTER_LOCKER_FETCH_ERROR = 'mpl/MASTER_LOCKER_FETCH_ERROR';

// TODO: these action types are not implemented
export const MASTER_DELETE_REQUEST = 'mpl/MASTER_DELETE_REQUEST';
export const MASTER_DELETE_SUCCESS = 'mpl/MASTER_DELETE_SUCCESS';
export const MASTER_DELETE_ERROR = 'mpl/MASTER_DELETE_ERROR';

export const MASTER_MODIFY_REQUEST = 'mpl/MASTER_MODIFY_REQUEST';
export const MASTER_MODIFY_SUCCESS = 'mpl/MASTER_MODIFY_SUCCESS';
export const MASTER_MODIFY_ERROR = 'mpl/MASTER_MODIFY_ERROR';

export const MASTER_MODIFY_ALLOWPINSAVE_REQUEST =
  'mpl/MASTER_MODIFY_ALLOWPINSAVE_REQUEST';
export const MASTER_MODIFY_ALLOWPINSAVE_SUCCESS =
  'mpl/MASTER_MODIFY_ALLOWPINSAVE_SUCCESS';
export const MASTER_MODIFY_ALLOWPINSAVE_ERROR =
  'mpl/MASTER_MODIFY_ALLOWPINSAVE_ERROR';

export const MASTER_LOCKER_UNLOAD_CURRENT = 'mpl/MASTER_LOCKER_UNLOAD_CURRENT';

// REDUCER

export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
    updating: false,
  },
  action
) {
  switch (action.type) {
    case MASTER_FETCH_REQUEST:
    case MASTER_LOCKER_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case MASTER_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.mpl,
        result: action.payload.result,
      };

    case MASTER_LOCKER_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };

    case MASTER_FETCH_ERROR:
    case MASTER_LOCKER_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case MASTER_DELETE_SUCCESS:
      const updatedEntities = omit(state.entities, action.id);
      return {
        ...state,
        entities: updatedEntities,
      };

    case MASTER_LOCKER_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    case MASTER_MODIFY_ALLOWPINSAVE_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case MASTER_MODIFY_ALLOWPINSAVE_SUCCESS:
    case MASTER_MODIFY_ALLOWPINSAVE_ERROR:
      return {
        ...state,
        updating: false,
      };
    default:
      return state;
  }
}

// Schemas

const mplSchema = new schema.Entity('mpl');

// Operations

export function fetchMaster(params) {
  return {
    [CALL_API]: {
      types: [MASTER_FETCH_REQUEST, MASTER_FETCH_SUCCESS, MASTER_FETCH_ERROR],
      promise: (httpClient) => httpClient.post('/master', params),
      schema: { data: [mplSchema] },
    },
  };
}

export function fetchMasterLocker(id) {
  return function (dispatch, getState) {
    const mplsState = getState().mpl;
    if (mplsState.entities && id in mplsState.entities) {
      // locker already exists in store - so just add it to the 'current' state
      dispatch({
        type: MASTER_LOCKER_FETCH_SUCCESS,
        payload: mplsState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            MASTER_LOCKER_FETCH_REQUEST,
            MASTER_LOCKER_FETCH_SUCCESS,
            MASTER_LOCKER_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/master/${id}`),
        },
      });
    }
  };
}

// export function modifyMasterLocker(params) {
//   return {
//     [CALL_API]: {
//       types: [MASTER_MODIFY_REQUEST, MASTER_MODIFY_SUCCESS, MASTER_MODIFY_ERROR],
//       promise: httpClient => httpClient.post(`/master/modify`, params),
//     }
//   };
// }

export function modifyAllowPinSave(id, bool) {
  return {
    [CALL_API]: {
      types: [
        MASTER_MODIFY_ALLOWPINSAVE_REQUEST,
        MASTER_MODIFY_ALLOWPINSAVE_SUCCESS,
        MASTER_MODIFY_ALLOWPINSAVE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post(`/master/update/allowPinSave`, {
          masterId: id,
          allowPinSave: bool,
        }),
      successMessage: `Successfully changed 'Allow PIN save'`,
    },
  };
}
