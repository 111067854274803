import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import LoginPage from 'components/pages/LoginPage';
import { checkAuth } from 'redux/modules/auth';
import brands from 'brands';
import { ThemeProvider } from '@material-ui/core/styles';
import Routes from 'routes';

const { theme } = brands[process.env.REACT_APP_PROJECT];

const setPageHeight = () => {
  const newHeight = window.innerHeight - 100;
  document.documentElement.style.setProperty(
    '--vh',
    `${newHeight > 320 ? newHeight : 320}px`
  );
};

export default function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.authenticated);

  useEffect(() => {
    setPageHeight();
    window.addEventListener('resize', setPageHeight);
    return () => {
      window.removeEventListener('resize', setPageHeight);
    };
  }, []);

  useEffect(() => {
    dispatch(checkAuth());

    // set brand title
    document.title = `${brands[process.env.REACT_APP_PROJECT].name} Admin`;

    // set brand favicon
    const link =
      document.querySelector('link[rel*="icon"]') ||
      document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = brands[process.env.REACT_APP_PROJECT].favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      {!isAuthenticated ? (
        <Router>
          <LoginPage />
        </Router>
      ) : (
        <Router>
          <Routes />
        </Router>
      )}
    </ThemeProvider>
  );
}
